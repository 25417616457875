import { Box, Divider, Switch, Typography } from '@mui/material'
import { ChangeEvent, PropsWithChildren, useEffect } from 'react'
import Filters from 'components/Toolbar/components/Filters'
import { SearchBar } from 'components/SearchBar'
import { settingsApi } from 'resources'
import { ORG_FILTERS_IDENTFIER } from '../../../constants'
interface IToolbarSearchbarProps {
  handleSearch: (event: ChangeEvent<HTMLInputElement>) => void
  filteredColumns?: any
  setFilteredColumns?: React.Dispatch<React.SetStateAction<string[]>> | any
  enableFilters?: boolean
  storageKey?: string
  /**
   * If `true`, only columns where column.sortable === true will be shown.
   * Defaults to false, meaning all columns are shown. this is useful for
   * filtering the table by columns that are dynamically generated.
   */
  onlySortable?: boolean
  local?: boolean
}

const ToolbarSearchbar = ({
  handleSearch,
  filteredColumns,
  setFilteredColumns,
  enableFilters = true,
  storageKey,
  onlySortable = false,
  local = false,
  children,
}: PropsWithChildren<IToolbarSearchbarProps>) => {
  const { updateFilters } = settingsApi()

  const handleToggle = async (
    event: React.ChangeEvent<HTMLInputElement>,
    column: any,
  ) => {
    const toggled = event.target.checked
    const index = filteredColumns?.findIndex(
      (c: any) => c.value === column.value,
    )

    if (index !== -1) {
      const activeColumnsClone = [...filteredColumns]
      activeColumnsClone[index] = { ...column, active: toggled }
      setFilteredColumns(activeColumnsClone)

      if (local && storageKey) {
        const existingFilters = JSON.parse(
          localStorage.getItem('tableFilters') || '{}',
        )
        existingFilters[storageKey] = activeColumnsClone
        localStorage.setItem('tableFilters', JSON.stringify(existingFilters))
      } else {
        const currentPage = ORG_FILTERS_IDENTFIER[location.pathname]
        await updateFilters(currentPage, activeColumnsClone)
      }
    }
  }

  useEffect(() => {
    if (local && storageKey) {
      const allFilters = JSON.parse(
        localStorage.getItem('tableFilters') || '{}',
      )
      const storedColumns = allFilters[storageKey]
      if (storedColumns) {
        setFilteredColumns(storedColumns)
      }
    }
  }, [local, storageKey, setFilteredColumns])

  const columnsToShow = onlySortable
    ? filteredColumns?.filter((col: any) => col.sortable)
    : filteredColumns

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <SearchBar onSearch={handleSearch} />
      {enableFilters && (
        <Filters>
          {columnsToShow && (
            <>
              <Typography width="350px" padding="10px">
                Toggle to enable or disable columns that you want to filter the
                table by.
              </Typography>

              {columnsToShow.map((column: any, index: number) => (
                <Box key={column.value}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '350px',
                      padding: '10px',
                    }}
                  >
                    <Typography>{column.label}</Typography>
                    <Switch
                      checked={column.active}
                      onChange={(event) => handleToggle(event, column)}
                    />
                  </Box>
                  {index !== columnsToShow.length - 1 && <Divider light />}
                </Box>
              ))}
            </>
          )}
          {!columnsToShow && children}
        </Filters>
      )}
    </Box>
  )
}

export default ToolbarSearchbar
