import { SelectChangeEvent } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { organizationApi, reportApi } from 'resources'
import { IMetricCard } from './components/MetricCard'
import SensorDoorIcon from '@mui/icons-material/SensorDoor'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import PieChartIcon from '@mui/icons-material/PieChart'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import PeopleIcon from '@mui/icons-material/People'
import PlaceIcon from '@mui/icons-material/Place'
import MoneyIcon from '@mui/icons-material/Money'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {
  IAnalyticsFilters,
  IAverageRevenuePerTransaction,
  IDoorHealth,
  IEarnings,
  INewUserGrowth,
  IOccupancyRate,
  IReportedIssueRate,
  ITotalDoors,
  ITotalLocations,
  ITotalTransactions,
  ITransactionRate,
  ITransactionsPerLocker,
} from 'models/Analytics'
import useDialog from 'hooks/useDialog'
import CustomDateRange from './components/CustomDateRange'
import {
  parseAverageRevenuePerTransactionValue,
  parseDoorHealthValue,
  parseEarningsValue,
  parseNewTransactionsValue,
  parseNewUserGrowthValue,
  parseOccupancyRateValue,
  parseOptionToDateRange,
  parseOptionToInterval,
  parseReportedIssuesRateValue,
  parseTotalDoorsValue,
  parseTotalLocationsValue,
  parseTotalTransactionsValue,
  parseTotalUsersValue,
  parseTransactionPerLockerValue,
  parseTransactionTimeAverageValue,
} from './helper'
import DashboardFilters from './components/DashboardFilters'
import DashboardContent from './components/DashboardContent'
import { NotificationDialog } from 'components'

const defaultFilters: IAnalyticsFilters = {
  from: null,
  to: null,
}

const Dashboard = () => {
  const [visibleMetrics, setVisibleMetrics] = useState<IMetricCard[]>(() => {
    return Array.from({ length: 5 }, (_, i) => ({
      metric: null,
      id: i,
      buildMetricObject: async () => {},
      metricsOptions: [],
      loading: false,
      setCurrentFetchingMetric: () => {},
    }))
  })
  const [subOrgs, setSubOrgs] = useState<any[]>([])
  const [filters, setFilters] = useState<IAnalyticsFilters>(defaultFilters)
  const [loading, setLoading] = useState<boolean>(false)
  const [openCustomDateRangeSelector, setOpenCustomDateRangeSelector] =
    useState<boolean>(false)
  const [selectedRange, setSelectedRange] = useState<string>('this_week')
  const [selectedSubOrg, setSelectedSubOrg] = useState<string | undefined>()
  const [selectedLocation, setSelectedLocation] = useState<string>('all')
  const [currentFetchingMetric, setCurrentFetchingMetric] = useState<string>('')
  const { dialog, displayMessage, closeDialog } = useDialog()
  const {
    getEarnings,
    getUserGrowth,
    getDoorHealth,
    getIssueRate,
    getOccupancyRate,
    getNewTransactionRate,
    getTransactionsPerLocker,
    getTotalTransactions,
    getTransactionTimeAverage,
    getTotalUsers,
    getTotalLocations,
    getAverageRevenuePerTransaction,
    getTotalDoors,
  } = reportApi()
  const { getOrgs } = organizationApi()

  const metricsData = [
    {
      icon: <MoneyIcon fontSize="medium" />,
      title: 'Earnings',
      legend: 'Revenue',
      accesor: 'earnings',
      parseFunction: (response: any) =>
        parseEarningsValue(response as IEarnings),
      fetchFunction: () => getEarnings(selectedSubOrg),
    },
    {
      icon: <PersonAddIcon fontSize="medium" />,
      title: 'New User Growth',
      accesor: 'newUserGrowth',
      parseFunction: (response: any) =>
        parseNewUserGrowthValue(response as INewUserGrowth),
      fetchFunction: () =>
        getUserGrowth(parseOptionToInterval(selectedRange), selectedSubOrg),
    },
    {
      icon: <SensorDoorIcon fontSize="medium" />,
      title: 'Door Health',
      accesor: 'doorHealth',
      parseFunction: (response: any) =>
        parseDoorHealthValue(response as IDoorHealth[], selectedLocation),
      fetchFunction: () => getDoorHealth(selectedSubOrg),
    },
    {
      icon: <WarningAmberIcon fontSize="medium" />,
      title: 'Reported Issues Rate',
      accesor: 'reportedIssuesRate',
      parseFunction: (response: any) =>
        parseReportedIssuesRateValue(response as IReportedIssueRate),
      fetchFunction: () =>
        getIssueRate(
          filters.from || undefined,
          filters.to || undefined,
          selectedLocation !== 'all' ? [selectedLocation] : undefined,
          selectedSubOrg,
        ),
    },
    {
      icon: <PieChartIcon fontSize="medium" />,
      title: 'Occupancy Rate',
      accesor: 'occupancyRate',
      parseFunction: (response: any) =>
        parseOccupancyRateValue(response as IOccupancyRate[], selectedLocation),
      fetchFunction: () =>
        getOccupancyRate(
          filters.from || undefined,
          filters.to || undefined,
          selectedSubOrg,
        ),
    },
    {
      icon: <SyncAltIcon fontSize="medium" />,
      title: 'New Transactions',
      accesor: 'newTransaction',
      parseFunction: (response: any) =>
        parseNewTransactionsValue(response as ITransactionRate),
      fetchFunction: () =>
        getNewTransactionRate(
          filters.from || undefined,
          filters.to || undefined,
          selectedSubOrg,
        ),
    },
    {
      icon: <SyncAltIcon fontSize="medium" />,
      title: 'Transaction Per Locker',
      accesor: 'transactionsPerLocker',
      parseFunction: (response: any) =>
        parseTransactionPerLockerValue(response as ITransactionsPerLocker),
      fetchFunction: () =>
        getTransactionsPerLocker(
          selectedLocation !== 'all' ? selectedLocation : undefined,
          filters.from || undefined,
          filters.to || undefined,
          selectedSubOrg,
        ),
    },
    {
      icon: <SyncAltIcon fontSize="medium" />,
      title: 'Total Transactions',
      accesor: 'totalTransactions',
      parseFunction: (response: any) =>
        parseTotalTransactionsValue(response as ITotalTransactions),
      fetchFunction: () =>
        getTotalTransactions(
          selectedLocation !== 'all' ? selectedLocation : undefined,
          undefined,
          selectedSubOrg,
        ),
    },
    {
      icon: <AccessTimeIcon fontSize="medium" />,
      title: 'Average Transaction Time',
      accesor: 'transactionTimeAverage',
      parseFunction: (response: any) =>
        parseTransactionTimeAverageValue(response as number),
      fetchFunction: () =>
        getTransactionTimeAverage(
          filters.from || undefined,
          filters.to || undefined,
          selectedSubOrg,
        ),
    },
    {
      icon: <PeopleIcon fontSize="medium" />,
      title: 'Active users in transaction',
      accesor: 'totalUsers',
      parseFunction: (response: any) =>
        parseTotalUsersValue(response as number),
      fetchFunction: () => getTotalUsers(selectedSubOrg),
    },
    {
      icon: <PlaceIcon fontSize="medium" />,
      title: 'Total Locations',
      accesor: 'totalLocations',
      parseFunction: (response: any) =>
        parseTotalLocationsValue(response as ITotalLocations),
      fetchFunction: () => getTotalLocations(selectedSubOrg),
    },
    {
      icon: <SyncAltIcon fontSize="medium" />,
      title: 'Average Revenue Per Transaction',
      accesor: 'averageRevenuePerTransaction',
      parseFunction: (response: any) =>
        parseAverageRevenuePerTransactionValue(
          response as IAverageRevenuePerTransaction,
        ),
      fetchFunction: () => getAverageRevenuePerTransaction(selectedSubOrg),
    },
    {
      icon: <SensorDoorIcon fontSize="medium" />,
      title: 'Total Doors',
      accesor: 'totalDoors',
      parseFunction: (response: any) =>
        parseTotalDoorsValue(response as ITotalDoors, selectedLocation),
      fetchFunction: () =>
        getTotalDoors(
          selectedLocation !== 'all' ? selectedLocation : undefined,
          selectedSubOrg,
        ),
    },
  ]

  const metricOptions = useMemo(
    () => metricsData.map((m) => ({ value: m.accesor, label: m.title })),
    [metricsData],
  )

  const buildMetricObject = async (id: number, metricKey: string) => {
    try {
      const metricDef = metricsData.find((m) => m.accesor === metricKey)
      if (!metricDef) return null

      const responseonse = await metricDef.fetchFunction()
      return {
        metric: metricDef.accesor,
        value: metricDef.parseFunction(responseonse),
        title: metricDef.title,
        icon: metricDef.icon,
        legend: metricDef.legend,
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
      return null
    }
  }

  const refreshMetrics = async () => {
    const toFetch = visibleMetrics.filter((m) => m.metric?.metric)

    if (toFetch.length === 0) return

    setLoading(true)
    try {
      const results = await Promise.all(
        toFetch.map(async (slot) => {
          const newMetricData = await buildMetricObject(
            slot.id,
            slot.metric!.metric,
          )
          return { slotId: slot.id, data: newMetricData }
        }),
      )

      const newVisible = visibleMetrics.map((old) => {
        const found = results.find((r) => r.slotId === old.id)
        if (found?.data) {
          return {
            ...old,
            metric: found.data,
          }
        }
        return old
      })

      setVisibleMetrics(newVisible)
    } finally {
      setLoading(false)
      setCurrentFetchingMetric('')
    }
  }

  const fetchSubOrgs = async () => {
    try {
      const subOrgs = await getOrgs(1, 1000)
      setSubOrgs(subOrgs.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  useEffect(() => {
    fetchSubOrgs()
  }, [])

  useEffect(() => {
    if (selectedRange === 'custom') {
      return
    }

    const newFrom = parseOptionToDateRange(selectedRange)
    const newTo = new Date()

    setFilters((prev) => {
      const sameFrom = prev.from?.getTime?.() === newFrom?.getTime?.()
      const sameTo = prev.to?.getTime?.() === newTo.getTime()
      if (sameFrom && sameTo) {
        return prev
      }
      return { from: newFrom, to: newTo }
    })
  }, [selectedRange])

  useEffect(() => {
    refreshMetrics()
  }, [selectedLocation, filters, selectedSubOrg])

  const handleCloseCustomDateDialog = () => {
    setOpenCustomDateRangeSelector(false)
  }

  const handleSelectDateRange = (event: SelectChangeEvent) => {
    setSelectedRange(event.target.value)
  }

  return (
    <>
      <DashboardFilters
        selectedRange={selectedRange}
        setOpenCustomDateRangeSelector={setOpenCustomDateRangeSelector}
        handleSelectDateRange={handleSelectDateRange}
        subOrgs={subOrgs}
        selectedSubOrg={selectedSubOrg}
        setSelectedLocation={setSelectedLocation}
        setSelectedSubOrg={setSelectedSubOrg}
      />
      <DashboardContent
        visibleMetrics={visibleMetrics}
        buildMetricObject={async (id, key) => {
          setCurrentFetchingMetric(key)
          setLoading(true)
          try {
            const newMetricData = await buildMetricObject(id, key)
            setVisibleMetrics((prev) =>
              prev.map((m) =>
                m.id === id
                  ? {
                      ...m,
                      metric: newMetricData || m.metric,
                    }
                  : m,
              ),
            )
          } finally {
            setLoading(false)
            setCurrentFetchingMetric('')
          }
        }}
        metricOptions={metricOptions}
        loading={loading}
        currentFetchingMetric={currentFetchingMetric}
        setCurrentFetchingMetric={setCurrentFetchingMetric}
        targetOrg={selectedSubOrg}
      />
      <CustomDateRange
        isOpen={openCustomDateRangeSelector}
        onClose={handleCloseCustomDateDialog}
        selectedRange={selectedRange}
        filters={filters}
        setFilters={setFilters}
      />
      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}
export default Dashboard
