import {
  Button,
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import { Box, Autocomplete, TextField } from '@mui/material'
import { DEFAULT_PAGE, DEVICES_PER_PAGE } from 'constants/pagination'
import { PRODUCT_DEFAULTS, PRODUCT_SHORT_DEFAULTS } from './../../constants'
import MultiEditProductForm from './MultiEditProductForm'
import useDialog from 'hooks/useDialog'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import ProductForm from './ProductForm'
import { IProduct, IProductList } from 'models/Product'
import productApi from 'resources/product/api/productApi'
import ProductRow from './ProductRow'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import { IProductGroup } from 'models/ProductGroup'
import { productGroupApi } from 'resources/product-group'
import useSortableHeader from 'hooks/useSortableHeader'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import LoadingTableData from 'components/Table/components/LoadingTableData/LoadingTableData'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ToolbarControl, DropdownOption } from 'types'
import useLoadingState from 'hooks/useLoadingState'
import useLocations from 'hooks/useLocations'
import { generateFilters, getStoredTableFilters } from 'utils/helpers'

const Inventory = () => {
  const [products, setProducts] = useState<IProductList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [productsBackup, setProductsBackup] = useState<IProduct[]>([])
  const [productGroups, setProductGroups] = useState<IProductGroup[]>([])
  const [currentProduct, setCurrentProduct] = useState<IProduct | undefined>()
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEVICES_PER_PAGE)
  const [search, setSearch] = useState<string>('')
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const [selectedProducts, setSelectedProducts] = useState<any[]>([])
  const [selectedRows, setSelectedRows] = useState<any[]>([])

  const [selectedLocation, setSelectedLocation] = useState<string>(() => {
    const saved = localStorage.getItem('inventoryLocationFilter')
    return saved || ''
  })
  const { getMany, remove } = productApi()
  const { getMany: getProductGroups } = productGroupApi()
  const { dialog, displayMessage, closeDialog } = useDialog()
  const [action, setAction] = useState<string>('')
  const [openMultiEdit, setOpenMultiEdit] = useState<boolean>(false)
  const { filteredColumns, setFilteredColumns } = useColumnFiltering({
    displayMessage,
  })
  const { loadingLocations, locationsOptions } = useLocations()

  const { loading, setLoading } = useLoadingState(true)

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'inventory',
      nestedProps: [
        {
          columnValue: 'product_name',
          path: 'name',
          defaultValue: '',
        },
        {
          columnValue: 'cost',
          path: 'price',
          defaultValue: 0,
        },
        {
          columnValue: 'sale_price',
          path: 'sales_price',
          defaultValue: 0,
        },
        {
          columnValue: 'assigned_locker',
          path: 'devices[0].locker_number',
          defaultValue: '-',
        },
        {
          columnValue: 'assigned_location',
          path: 'devices[0].location.name',
          defaultValue: '-',
        },
      ],
    })

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )
  }
  const loadTableFilters = (firstItem: any) => {
    try {
      const storageKey = 'filteredColumns_inventory'
      const storedColumns = getStoredTableFilters(storageKey)

      if (storedColumns) {
        setFilteredColumns(storedColumns)
      } else {
        const generatedColumns = generateFilters(
          firstItem,
          PRODUCT_DEFAULTS,
          PRODUCT_SHORT_DEFAULTS,
        )
        setFilteredColumns(generatedColumns)
        const storedFilters = JSON.parse(
          localStorage.getItem('tableFilters') || '{}',
        )
        storedFilters[storageKey] = generatedColumns
        localStorage.setItem('tableFilters', JSON.stringify(storedFilters))
      }
    } catch (error) {
      console.error('Error loading table filters:', error)
      displayMessage(
        'Error loading table filters: ' + (error as Error).message,
        'error',
      )
    }
  }
  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedProducts(products.items)
    setSelectedRows(() =>
      checked ? products.items.map((product) => product.id) : [],
    )
  }

  const handleDeleteMany = async () => {
    try {
      await remove(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchProducts()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const handleEditProduct = (product: IProduct) => {
    setCurrentProduct(product)
    setOpenSidebar(true)
  }

  const handleCloseSidebar = () => {
    setOpenSidebar(false)
    setCurrentProduct(undefined)
  }

  const handleCloseMultiEdit = () => {
    setOpenMultiEdit(false)
    setAction('')
  }

  const fetchProducts = async (
    showNewestFirst: boolean = false,
  ): Promise<void> => {
    setLoading(true)

    try {
      const data = await getMany(
        currentPage + 1,
        rowsPerPage,
        search,
        selectedLocation,
      )

      const items = data?.items ?? []

      setProducts({
        items,
        total: data?.total ?? 0,
        pages: data?.pages ?? 0,
      })

      setProductsBackup(items)

      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const fetchProductGroups = async (): Promise<void> => {
    try {
      const productGroups = await getProductGroups(1, 1000)
      setProductGroups(productGroups.items)
      loadTableFilters(productGroups.items[0])
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleSelectLocation = (
    event: React.SyntheticEvent,
    value: DropdownOption | null,
  ) => {
    const newValue = value ? value.value : ''
    setSelectedLocation(newValue)
    localStorage.setItem('inventoryLocationFilter', newValue)
  }

  useEffect(() => {
    fetchProducts()
    fetchProductGroups()
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [search, currentPage, rowsPerPage, selectedLocation])

  useEffect(() => {
    if (action === 'edit') {
      setOpenMultiEdit(true)
    }
  }, [action])

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(products.items, productsBackup),
    [products, order, orderBy],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          variant="contained"
          name="addSize"
          disabled={loading}
          onClick={() => setOpenSidebar(true)}
        >
          Add Product
        </Button>
      ),
    },

    {
      display: true,
      render: (
        <Autocomplete
          key="3"
          disablePortal
          options={locationsOptions}
          value={
            locationsOptions.find(
              (option) => option.value === selectedLocation,
            ) || null
          }
          renderInput={(params) => (
            <TextField {...params} label="Select a location..." />
          )}
          onChange={handleSelectLocation}
          size="small"
          disabled={loadingLocations || loading}
          loadingText="Loading..."
        />
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          storageKey="filteredColumns_inventory"
          local={true}
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelected={selectedRows.length}
          itemsSelectedData={selectedProducts}
          handleAction={handleDeleteMany}
          actionsAllowed={['delete', 'export', 'edit']}
          model="product"
          setAction={setAction}
        />
      )}
      {!loading && visibleRows.length > 0 && (
        <>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={filteredColumns.filter((c) => c.active)}
              handleSelectAll={handleSelectAll}
            />
            <TableBody>
              {visibleRows.map((product) => (
                <ProductRow
                  key={product.id}
                  product={product}
                  success={fetchProducts}
                  displayMessage={displayMessage}
                  filteredColumns={filteredColumns}
                  handleEditProduct={handleEditProduct}
                  handleSelectRow={handleSelectRow}
                  selected={selectedRows.includes(product.id)}
                  groups={productGroups}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            totalItems={products.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingTableData isLoading={loading} />

      <NoData condition={!loading && visibleRows.length === 0} />

      {openSidebar && (
        <SideBar open={openSidebar} onClose={handleCloseSidebar}>
          <ProductForm
            product={currentProduct}
            success={fetchProducts}
            onClose={handleCloseSidebar}
            displayMessage={displayMessage}
            groups={productGroups}
          />
        </SideBar>
      )}
      {openMultiEdit && (
        <SideBar open={openMultiEdit} onClose={handleCloseMultiEdit}>
          <MultiEditProductForm
            products={selectedRows}
            success={fetchProducts}
            onClose={handleCloseMultiEdit}
            displayMessage={displayMessage}
            groups={productGroups}
          />
        </SideBar>
      )}
      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}

export default Inventory
