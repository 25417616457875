import { Chip, Link, Tooltip, Typography } from '@mui/material'
import {
  ConfirmDialog,
  IDialogType,
  IconButton,
  TableCell,
  TableRow,
} from 'components'
import { IOrganization } from 'models'
import moment from 'moment'
import { InfoOutlined, RefreshRounded } from '@mui/icons-material'
import HistoryIcon from '@mui/icons-material/History'
import { useState } from 'react'
import { organizationApi } from 'resources'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}

interface IProductRowProps {
  organization: IOrganization
  myOrg: IOrganization
  success: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  handleEditOrganization: (organization: IOrganization) => void
}

const OrganizationRow = ({
  organization,
  myOrg,
  success,
  displayMessage,
  handleEditOrganization,
}: IProductRowProps) => {
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)
  const [expanded, setExpanded] = useState(false)

  const { archive, restore } = organizationApi()

  const archiveOrganization = async () => {
    try {
      setConfirmationMessage({ isOpen: false, message: '', action: '' })
      await archive(organization.id)
      displayMessage('Organization has been archived!', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const restoreOrganization = async () => {
    try {
      setConfirmationMessage({ isOpen: false, message: '', action: '' })
      await restore(organization.id)
      displayMessage('Organization has been restored!', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <Link
            sx={{
              display: 'flex',
              justifyContent: 'center',
              cursor: organization.active ? 'pointer' : undefined,
              textDecoration: 'none',
              color: !organization.active ? 'text.secondary' : undefined,
            }}
            onClick={() => handleEditOrganization(organization)}
          >
            {organization.white_label?.app_name || organization.name}

            {!organization.active && (
              <Chip
                color="default"
                label="Archived"
                size="small"
                sx={{
                  marginLeft: '0.5rem',
                }}
              />
            )}
          </Link>
        </TableCell>
        <TableCell>
          <Link
            sx={{
              cursor: organization.active ? 'pointer' : undefined,
              textDecoration: 'none',
              color: !organization.active ? 'text.secondary' : undefined,
            }}
            href={
              organization.active
                ? `https://${organization.name}.koloni.io`
                : undefined
            }
          >
            {organization.name}.koloni.io
          </Link>
        </TableCell>
        <TableCell>
          <Typography
            fontSize={14}
            color={!organization.active ? 'text.secondary' : undefined}
          >
            {organization.white_label?.organization_owner || '-'}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography
            fontSize={14}
            color={!organization.active ? 'text.secondary' : undefined}
          >
            {moment(organization.created_at).format('MM/DD/YYYY hh:mm A')}
          </Typography>
        </TableCell>
        <TableCell>
          {myOrg.id === organization.id ? (
            <>
              <IconButton
                onClick={() => {
                  displayMessage(
                    'You cannot archive the organization you are currently logged into.',
                    'error',
                  )
                }}
              >
                <InfoOutlined />
              </IconButton>
            </>
          ) : (
            <>
              {!organization.active && (
                <IconButton
                  onClick={() => {
                    setConfirmationMessage({
                      isOpen: true,
                      message: `Are you sure you want to restore this organization?`,
                      action: 'cancelDelete',
                    })
                  }}
                >
                  <Tooltip title="Restore Organization">
                    <RefreshRounded />
                  </Tooltip>
                </IconButton>
              )}

              {organization.active && (
                <IconButton
                  onClick={() => {
                    setConfirmationMessage({
                      isOpen: true,
                      message: `Are you sure you want to archive this organization?`,
                      action: 'delete',
                    })
                  }}
                >
                  <Tooltip title="Archive Organization">
                    <HistoryIcon />
                  </Tooltip>
                </IconButton>
              )}
            </>
          )}
        </TableCell>
        <TableCell>
          <IconButton onClick={handleExpand}>
            <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Tooltip>
          </IconButton>
        </TableCell>
      </TableRow>

      {expanded && organization.sub_orgs && organization.sub_orgs.length > 0
        ? organization.sub_orgs.map((subOrg) => (
            <TableRow
              key={subOrg.id}
              sx={{
                backgroundColor: 'action.hover',
              }}
            >
              <TableCell>
                <Typography variant="body2">
                  <Link
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      cursor: organization.active ? 'pointer' : undefined,
                      textDecoration: 'none',
                      color: !organization.active
                        ? 'text.secondary'
                        : undefined,
                    }}
                    onClick={() => handleEditOrganization(subOrg)}
                  >
                    {subOrg.name}
                  </Link>
                </Typography>
              </TableCell>
              <TableCell>
                <Link
                  sx={{
                    cursor: subOrg.active ? 'pointer' : undefined,
                    textDecoration: 'none',
                    color: !subOrg.active ? 'text.secondary' : undefined,
                  }}
                  href={
                    subOrg.active
                      ? `https://${subOrg.name}.koloni.io`
                      : undefined
                  }
                >
                  {subOrg.name}.koloni.io
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {subOrg.white_label?.organization_owner || '-'}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2">
                  {moment(subOrg.created_at).format('MM/DD/YYYY hh:mm A')}
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          ))
        : expanded && (
            <TableRow>
              <TableCell
                colSpan={6}
                sx={{ textAlign: 'center', backgroundColor: 'action.hover' }}
              >
                <Typography variant="body2" color="text.secondary">
                  No sub-organizations found
                </Typography>
              </TableCell>
            </TableRow>
          )}

      <ConfirmDialog
        open={confirmationMessage.isOpen}
        message={confirmationMessage.message}
        onClose={() => {
          setConfirmationMessage({ isOpen: false, message: '', action: '' })
        }}
        onClickConfirm={
          confirmationMessage.action === 'delete'
            ? archiveOrganization
            : restoreOrganization
        }
        onClickCancel={() => {
          setConfirmationMessage({ isOpen: false, message: '', action: '' })
        }}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default OrganizationRow
