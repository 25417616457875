import { useEffect, useState, ChangeEvent, useMemo } from 'react'
import { IGroup, IGroupList } from 'models'
import { groupApi } from 'resources'
import {
  Table,
  TableBody,
  SideBar,
  TablePagination,
  NotificationDialog,
} from 'components'
import { Button } from '@mui/material'
import GroupForm from './GroupForm'
import GroupRow from './GroupRow'
import useDialog from 'hooks/useDialog'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import useSortableHeader from 'hooks/useSortableHeader'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import LoadingTableData from 'components/Table/components/LoadingTableData/LoadingTableData'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'

const Groups = () => {
  const [groups, setGroups] = useState<IGroupList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [groupsBackup, setGroupsBackup] = useState<IGroup[]>([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [search, setSearch] = useState<string>('')
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectedRowData, setSelectedRowData] = useState<any[]>([])
  const [currentGroup, setCurrentGroup] = useState<IGroup | undefined>()

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'groups',
      nestedProps: [],
    })

  const { getMany, deleteGroup } = groupApi()

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { loading, setLoading } = useLoadingState(true)

  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )

    setSelectedRowData((previousValue) =>
      checked
        ? [...previousValue, groups.items.find((group) => group.id === id)]
        : previousValue.filter((row) => row.id !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? groups.items.map((device) => device.id) : [],
    )

    setSelectedRowData(() => (checked ? groups.items : []))
  }

  const handleDeleteMany = async () => {
    try {
      await deleteGroup(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchGroups()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchGroups = async (showNewestFirst: boolean = false) => {
    try {
      setLoading(true)
      const groups = await getMany(currentPage + 1, rowsPerPage, search)
      setGroupsBackup([...groups.items])
      setGroups(groups)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const handleEditGroup = (group: IGroup) => {
    setCurrentGroup(group)
    setOpenSidebar(true)
  }

  useEffect(() => {
    getDefaultFilters()
    fetchGroups()
  }, [])

  useEffect(() => {
    if (!openSidebar && currentGroup) setCurrentGroup(undefined)
  }, [openSidebar])

  useEffect(() => {
    fetchGroups()
  }, [search, currentPage, rowsPerPage])

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(groups.items, groupsBackup),
    [groups, order, orderBy],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={() => setOpenSidebar(true)}
        >
          Add Group
        </Button>
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          storageKey="filteredColumns_groups"
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelected={selectedRows.length}
          handleAction={handleDeleteMany}
          actionsAllowed={['delete', 'export']}
          itemsSelectedData={selectedRowData}
          model="group"
        />
      )}
      {!loading && groups.items.length > 0 && (
        <>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={filteredColumns.filter((c) => c.active)}
              handleSelectAll={handleSelectAll}
            />
            <TableBody>
              {visibleRows.map((group) => (
                <GroupRow
                  key={group.id}
                  group={group}
                  success={() => fetchGroups()}
                  filteredColumns={filteredColumns}
                  displayMessage={displayMessage}
                  handleSelectRow={handleSelectRow}
                  selected={selectedRows.includes(group.id)}
                  handleEditGroup={handleEditGroup}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            totalItems={groups.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingTableData isLoading={loading} />

      <NoData condition={!loading && groups.items.length === 0} />

      <SideBar open={openSidebar} onClose={() => setOpenSidebar(false)}>
        <GroupForm
          group={currentGroup}
          success={fetchGroups}
          onClose={() => setOpenSidebar(false)}
          displayMessage={displayMessage}
        />
      </SideBar>
      <NotificationDialog
        message={dialog.message}
        open={dialog.isOpen}
        onClose={closeDialog}
        type={dialog.type}
      />
    </>
  )
}

export default Groups
