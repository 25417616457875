import { useApiRequest } from 'utils'
import { IOrganization, IOrganizationList, PublicOrg } from 'models'
import { getErrorMessage } from 'utils/helpers'

const organizationApi = () => {
  const { fetchEndpointUnauthorized, fetchPartnerEndpoint } = useApiRequest()

  const getOrgs = async (
    page: number,
    size: number,
    search: string = '',
    active: boolean = false,
    expand: boolean = true,
  ): Promise<IOrganizationList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
      active: active.toString(),
      expand: expand.toString(),
    })
    const endpoint = `organizations?${urlParams.toString()}`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const restore = async (orgId: string): Promise<{ detail: string }> => {
    const endpoint = `organizations/${orgId}/cancel`
    const method = 'DELETE'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const archive = async (orgId: string): Promise<{ detail: string }> => {
    const endpoint = `organizations/${orgId}`
    const method = 'DELETE'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (newOrg): Promise<IOrganization> => {
    const endpoint = `organizations`
    const method = 'POST'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: newOrg,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getOrgDetailsPublic = async (orgName: string): Promise<PublicOrg> => {
    const endpoint = `organization?name=${orgName}`
    const method = 'GET'

    try {
      const response = await fetchEndpointUnauthorized({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const getSelfOrg = async (): Promise<IOrganization> => {
    const endpoint = `organizations/self`
    const method = 'GET'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const patchOrgFeatures = async (
    orgId: string,
    orgFeatures: any,
  ): Promise<any> => {
    const endpoint = `organizations/${orgId}`
    const method = 'PATCH'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        headers: {
          'Content-Type': 'application/json',
        },
        method,
        body: JSON.stringify(orgFeatures),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return {
    getOrgDetailsPublic,
    getOrgs,
    archive,
    restore,
    create,
    getSelfOrg,
    patchOrgFeatures,
  }
}
export default organizationApi
