import { Link, useTheme } from '@mui/material'
import { TextField } from 'components'
import { IProfileSettings } from 'models/ProfileSettings'
import { useFormContext } from 'react-hook-form'

interface IChangePasswordProps {
  passwordStrengthElement: JSX.Element | null
}

const ChangePassword = ({ passwordStrengthElement }: IChangePasswordProps) => {
  const theme = useTheme()
  const { setValue, watch } = useFormContext<IProfileSettings>()

  const showChangePassword = watch('show_change_password')

  const toggleShowChangePassword = () =>
    setValue('show_change_password', !showChangePassword)

  const newPassword: string = watch('new_password')

  return (
    <>
      <Link
        sx={{
          color: theme.palette.action.active,
          cursor: 'pointer',
        }}
        onClick={toggleShowChangePassword}
      >
        Change Password
      </Link>

      {showChangePassword && (
        <>
          <TextField
            type="password"
            name="password"
            label="Enter your password"
            placeholder="Enter your password"
            rules={{
              required: showChangePassword ? 'Password is required' : false,
            }}
          />

          <TextField
            type="password"
            name="new_password"
            label="Create new password"
            placeholder="Create new password"
            rules={{
              required: showChangePassword ? 'New password is required' : false,
            }}
          />

          {passwordStrengthElement}

          <TextField
            type="password"
            name="confirm_password"
            label="Enter new password"
            placeholder="Enter new password"
            rules={{
              validate: (value) =>
                value === newPassword || 'The passwords do not match',
            }}
          />
        </>
      )}
    </>
  )
}

export default ChangePassword
