import { Box, Theme, Typography, useMediaQuery } from '@mui/material'
import { Form, IDialogType, TextField } from 'components'
import { FormActions } from 'components/Form/components/FormActions'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { useUserSession } from 'hooks/useUserSession'
import { IProfileSettings } from 'models/ProfileSettings'
import { FormProvider, useForm } from 'react-hook-form'
import ChangePassword from './ChangePassword'
import { settingsApi } from 'resources'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { usePasswordStrength } from 'hooks/usePasswordStrength'
import { setUserSession } from 'redux/user-session/Action'
import { useDispatch } from 'react-redux'

interface IProfileProps {
  displayMessage: (message: string, type?: IDialogType) => void
}

const Profile = ({ displayMessage }: IProfileProps) => {
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const { session: userSession, isHelpDesk } = useUserSession()
  const { updateProfileSettings, changePassword } = settingsApi()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const methods = useForm<IProfileSettings>({
    defaultValues: {
      user_id: '',
      email: '',
      first_name: '',
      last_name: '',
      show_change_password: false,
      confirm_password: '',
      new_password: '',
      password: '',
    },
  })

  const setInitialFields = () => {
    methods.setValue('user_id', userSession.user_id)
    methods.setValue('email', userSession.email)
    methods.setValue('first_name', userSession.first_name || '')
    methods.setValue('last_name', userSession.last_name || '')
  }

  useEffect(() => {
    setInitialFields()
  }, [userSession])

  if (isHelpDesk) return null

  const onSubmit = async (formData: IProfileSettings) => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('currentUser')!)

      setLoading(true)
      if (formData.show_change_password) {
        await changePassword(
          currentUser.pool.clientId,
          currentUser.signInUserSession.refreshToken.token,
          formData,
        )
      }

      await updateProfileSettings(formData)
      const newSession = {
        ...userSession,
        name: `${formData.first_name} ${formData.last_name}`,
        first_name: formData.first_name,
        last_name: formData.last_name,
      }
      localStorage.setItem('userSession', JSON.stringify(newSession))
      dispatch(setUserSession(newSession))
      displayMessage('Settings updated successfully', 'success')
    } catch (error) {
      displayMessage(
        `${
          (error as Error)?.message ||
          'Something went wrong! Please try again later.'
        }`,
      )
    } finally {
      setLoading(false)
    }
  }

  const disableSubmit = Object.keys(methods.formState.errors).length > 0
  const showChangePassword = methods.watch('show_change_password')
  const newPassword: string = methods.watch('new_password')
  const passwordStrength = usePasswordStrength(newPassword)

  return (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '0 auto',
            whiteSpace: 'nowrap',
            width: smDown ? '100%' : 400,
          }}
        >
          <FormWrapper>
            <Typography textAlign="center" variant="h3">
              Profile
            </Typography>

            <TextField
              name="first_name"
              label="Name"
              placeholder="Name"
              rules={{
                required: 'First name is required',
              }}
            />

            <TextField
              name="last_name"
              label="Last Name"
              placeholder="Last Name"
            />

            <TextField
              name="email"
              disabled
              label="Email"
              placeholder="Email"
            />

            <ChangePassword
              passwordStrengthElement={passwordStrength.element}
            />

            <FormActions
              cancelText="Cancel"
              onClose={() => navigate('/home')}
              loading={loading}
              disableSubmit={
                disableSubmit ||
                (showChangePassword &&
                  passwordStrength.strengthLabel === 'Weak')
              }
            />
          </FormWrapper>
        </Box>
      </Form>
    </FormProvider>
  )
}

export default Profile
