import { IMember } from 'models'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Form, TextField, SelectField, Tabs, IDialogType } from 'components'
import { memberApi } from 'resources'
import { ROLES } from 'pages/People/constants'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { BulkUploadEntity } from 'components/BulkUploadEntity'
import { SyntheticEvent, useState } from 'react'
import useLoadingState from 'hooks/useLoadingState'
import { getUserRole } from 'utils/helpers'
import { Checkbox, FormControl, FormControlLabel } from '@mui/material'

interface IMemberFormProps {
  displayMessage: (message: string | JSX.Element, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => Promise<void>
  member: IMember | undefined
  onClose: () => void
}

const MemberForm = ({
  displayMessage,
  success,
  member,
  onClose,
}: IMemberFormProps) => {
  const { loading, setLoading } = useLoadingState()
  const { create, update } = memberApi()

  const methods = useForm<IMember>({
    defaultValues: {
      ...member,
      send_reported_issues: member?.send_reported_issues || false,
    },
  })

  const [currentTab, setCurrentTab] = useState<string>('IndividualUpload')

  const handleTabChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  const onSubmit: SubmitHandler<IMember> = async (data: IMember) => {
    try {
      setLoading(true)
      if (member) {
        await update(
          {
            ...data,
            name: data.last_name
              ? `${data.first_name} ${data.last_name}`
              : data.first_name,
          },
          member.user_id,
        )
        success(true)
        displayMessage('Member updated successfully!', 'success')
        onClose()
      } else {
        await create({
          ...data,
          name: data.last_name
            ? `${data.first_name} ${data.last_name}`
            : data.first_name,
          email: data.email.toLowerCase().trim(),
        })
        success(true)
        displayMessage('New member created successfully!', 'success')
        onClose()
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const MemberFormElement = (
    <FormProvider {...methods}>
      <Form onSubmit={onSubmit}>
        <FormWrapper title={member ? 'Edit Member' : 'Add Member'}>
          <TextField
            name="email"
            label="Email"
            placeholder="Email"
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            }}
            disabled={!!member}
          />

          <TextField
            name="first_name"
            label="First name"
            placeholder="First name"
            rules={{
              required: 'First name is required',
            }}
          />

          <TextField
            name="last_name"
            label="Last name"
            placeholder="Last name"
          />

          <TextField
            name="pin_code"
            label="Pin Code"
            placeholder="0000"
            onlyInteger={true}
            rules={{
              pattern: {
                value: /^(?!1234$)\d{4,8}$/,
                message: 'Invalid pin code',
              },
            }}
            helperText="Pin code must be between 4 to 8 digits"
          />

          <SelectField
            name="role"
            label="Role"
            placeholder="Role"
            items={ROLES}
            sx={{
              width: '100%',
            }}
            rules={{
              required: 'Role is required',
            }}
          />

          <FormControl sx={{ width: '50%' }}>
            <FormControlLabel
              control={
                <Checkbox
                  {...methods.register('send_reported_issues')}
                  defaultChecked={member?.send_reported_issues || false}
                />
              }
              label="Send reported issues"
            />
          </FormControl>

          <FormActions
            onClose={onClose}
            loading={loading}
            disableSubmit={disableSubmit}
          />
        </FormWrapper>
      </Form>
    </FormProvider>
  )

  return (
    <>
      {member && MemberFormElement}
      {getUserRole() !== 'admin' && !member && MemberFormElement}
      {getUserRole() === 'admin' && !member && (
        <Tabs
          tabs={[
            {
              label: 'Individual Upload',
              value: 'IndividualUpload',
              children: MemberFormElement,
            },
            {
              label: 'Bulk Upload',
              value: 'BulkUpload',
              children: (
                <BulkUploadEntity
                  entity="members"
                  successForm={() => success?.()}
                  onClose={onClose}
                  displayMessage={displayMessage}
                />
              ),
            },
          ]}
          currentTab={currentTab}
          handleChange={handleTabChange}
        />
      )}
    </>
  )
}
export default MemberForm
