import { PasswordStrength } from 'components/PasswordStrength'
import { useMemo } from 'react'

const MEDIUM_REGEX =
  /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})./

const STRONG_REGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{12,}$/

export const usePasswordStrength = (password: string) =>
  useMemo(() => {
    if (!password)
      return {
        strengthLabel: 'Weak',
        element: null,
      }
    if (STRONG_REGEX.test(password)) {
      return {
        strengthLabel: 'Strong',
        element: (
          <PasswordStrength value={100} label="Strong" color="success" />
        ),
      }
    }
    if (MEDIUM_REGEX.test(password)) {
      return {
        strengthLabel: 'Strong',
        element: <PasswordStrength value={50} label="Medium" color="warning" />,
      }
    }

    return {
      strengthLabel: 'Weak',
      element: <PasswordStrength value={25} label="Weak" color="error" />,
    }
  }, [password])
