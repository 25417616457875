import { useApiRequest } from 'utils'
import { IProduct, IProductList } from 'models/Product'
import { getErrorMessage } from 'utils/helpers'

const productApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
    id?: string,
  ): Promise<IProductList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      ...(id && { by_location: id ?? '' }),
      search,
    })
    const endpoint = `products?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const clone = async (id_product: string): Promise<IProduct> => {
    const endpoint = `products/${id_product}/duplicate`
    const method = 'POST'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (product: FormData): Promise<IProduct> => {
    const endpoint = `products`
    const method = 'POST'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: product,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const uploadCSV = async (file: File) => {
    const endpoint = `products/csv`
    const method = 'POST'
    const body = new FormData()

    body.append('file', file)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (
    productId: string,
    product: FormData,
  ): Promise<IProduct> => {
    const endpoint = `products/${productId}`
    const method = 'PUT'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: product,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const patch = async (ids: string[], productData: Partial<IProduct>) => {
    const endpoint = `products`
    const method = 'PATCH'

    const body = {
      id_products: ids,
      product: productData,
    }

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return {
        status: response.status,
        result: await response.json(),
      }
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]): Promise<IProduct> => {
    const endpoint = `products`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return { getMany, create, clone, uploadCSV, update, remove, patch }
}
export default productApi
