import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useTheme,
  Box,
} from '@mui/material'
import { useFormContext, Controller } from 'react-hook-form'
import { ISelectFieldProps, ErrorMessage } from 'components'

const SelectField = ({
  name,
  label,
  items,
  disabled,
  rules,
  required = false,
  isReadOnly = false,
  onOpen = undefined,
  defaultValue = undefined,
  id,
}: ISelectFieldProps) => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext()

  const theme = useTheme()

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Select
            {...register(name, rules)}
            {...field}
            id={id}
            label={label}
            disabled={disabled}
            required={required}
            readOnly={isReadOnly}
            onOpen={onOpen}
            defaultValue={defaultValue}
            inputProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    backgroundImage: 'none',
                    boxShadow: 3,
                    backgroundColor:
                      theme.palette.mode === 'dark' ? '#2A2E34' : '#f7f7f7',
                  },
                },
              },
            }}
          >
            {items.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}

                {item.note && (
                  <Box
                    sx={{
                      position: 'absolute',
                      color: theme.palette.grey[500],
                      fontStyle: 'italic',
                      fontSize: '0.9rem',
                      right: 16,
                    }}
                  >
                    {item.note}
                  </Box>
                )}
              </MenuItem>
            ))}
          </Select>
        )}
        rules={rules}
      />
      <ErrorMessage errors={errors} name={name} />
    </FormControl>
  )
}
export default SelectField
