import { ISession } from 'models/Session'
import { setUserSession } from './Action'

const initialState: ISession = {
  email: '',
  enabled: true,
  first_name: '',
  id_locations: [],
  last_name: '',
  name: '',
  role: '',
  user_id: '',
  user_status: '',
}

const userSessionReducer = (
  state = initialState,
  action: ReturnType<typeof setUserSession>,
) => {
  switch (action.type) {
    case 'setUserSession':
      return action.payload
    default:
      return state
  }
}

export default userSessionReducer
