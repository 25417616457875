import { Alert, Box, Typography } from '@mui/material'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { FormControl, TextField as MUITextField } from '@mui/material'
import { Button, NotificationDialog } from 'components'
import { SetNewPasswordFormStyles } from './styles'

import { usePasswordStrength } from 'hooks/usePasswordStrength'
import { useAuth } from 'resources'

const SetNewPassword = (): ReactElement => {
  const [inputValues, setInputValues] = useState({
    newPassword: '',
    confirmPassword: '',
  })
  const [dialog, setDialog] = useState<{
    isOpen: boolean
    message: string
  }>({
    isOpen: false,
    message: '',
  })
  const [error, setError] = useState('')
  const { SX } = SetNewPasswordFormStyles()
  const navigate = useNavigate()
  const { configureAmplify } = useAuth()

  const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}')
  const oldPassword = localStorage.getItem('oldPassword') || ''

  const passwordStrength = usePasswordStrength(inputValues.newPassword)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (error) {
      setError('')
    }

    const user: CognitoUser = await Auth.signIn(
      currentUser.challengeParam.userAttributes.email.toLowerCase().trim(),
      oldPassword,
    )

    if (user) {
      try {
        await Auth.completeNewPassword(user, inputValues.newPassword)
        setDialog({
          isOpen: true,
          message: 'Password reset was successful. Please login',
        })
        localStorage.removeItem('oldPassword')
        localStorage.setItem('firstTimeLogin', 'true')
      } catch (error) {
        setDialog({ isOpen: true, message: (error as Error).message })
        setError((error as Error).message)
      }
    }
  }

  const passwordsMatch =
    inputValues.newPassword === inputValues.confirmPassword &&
    inputValues.newPassword !== ''

  const initAmplify = () => {
    configureAmplify()
  }

  initAmplify()

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={SX.CONTAINER}>
        <Typography sx={SX.TITLE}>Reset your password</Typography>
        <Typography sx={SX.DESCRIPTION}>
          Before continuing, please reset your password.
        </Typography>

        <Typography sx={SX.DESCRIPTION}>
          Your password needs to have at least 8 characters, contain at least
          one uppercase letter, one lowercase letter, one number and one special
          character.
        </Typography>

        <Box sx={SX.FIELDS_CONTAINER}>
          <FormControl fullWidth>
            <MUITextField
              onChange={handleInputChange}
              name="newPassword"
              fullWidth
              label="Password"
              placeholder="Password"
              type="password"
            />
          </FormControl>
        </Box>
        {passwordStrength.element && (
          <Box sx={{ marginTop: '10px' }}>{passwordStrength.element}</Box>
        )}

        <Box sx={SX.FIELDS_CONTAINER}>
          <FormControl fullWidth>
            <MUITextField
              onChange={handleInputChange}
              name="confirmPassword"
              fullWidth
              label="Confirm password"
              placeholder="Confirm password"
              type="password"
            />
          </FormControl>
          {!passwordsMatch &&
            inputValues.newPassword !== '' &&
            inputValues.confirmPassword !== '' && (
              <Alert variant="filled" severity="error">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Passwords do not match!
                </Box>
              </Alert>
            )}
          {passwordsMatch && (
            <Alert variant="filled" severity="success">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                Passwords match!
              </Box>
            </Alert>
          )}
        </Box>
        <Box sx={SX.BUTTONS_CONTAINER}>
          <Button
            variant="contained"
            type="submit"
            disabled={
              !passwordsMatch || passwordStrength.strengthLabel === 'Weak'
            }
            sx={{ width: 'auto' }}
          >
            Reset Password
          </Button>
          <Button
            variant="contained"
            type="reset"
            sx={{ width: 'auto' }}
            onClick={() => {
              setInputValues({
                newPassword: '',
                confirmPassword: '',
              })
            }}
          >
            Clear
          </Button>
        </Box>
      </Box>
      <NotificationDialog
        open={dialog.isOpen}
        onClose={() => {
          setDialog({
            isOpen: false,
            message: '',
          })
          if (!error) {
            navigate('/home')
          }
        }}
        message={dialog.message}
      />
    </form>
  )
}

export default SetNewPassword
