/* eslint-disable react/display-name */
import { ContentCopy, DeleteForever, QrCode } from '@mui/icons-material'
import { Checkbox, Link, Tooltip, useTheme, Box } from '@mui/material'
import {
  ConfirmDialog,
  IDialogType,
  IconButton,
  Image,
  QRGenerator,
  TableCell,
  TableRow,
  BarCodeGenerator,
} from 'components'
import { IProduct } from 'models/Product'
import { IProductGroup } from 'models/ProductGroup'
import { useState, useMemo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { productApi } from 'resources/product/api'
import { getUserRole, parseCurrency, parseHexToRGB } from 'utils/helpers'

interface IProductRowProps {
  product: IProduct
  success: () => void
  displayMessage: (message: string, type?: IDialogType) => void
  filteredColumns: any[]
  handleEditProduct: (product: IProduct) => void
  handleSelectRow: (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => void
  selected: boolean
  groups: IProductGroup[]
}

const defaultConfirmation = {
  isOpen: false,
  message: '',
  action: '',
}

const ProductRow = ({
  product,
  success,
  displayMessage,
  filteredColumns,
  handleEditProduct,
  handleSelectRow,
  selected,
  groups,
}: IProductRowProps) => {
  const [confirmationMessage, setConfirmationMessage] =
    useState(defaultConfirmation)
  const [productToClone, setProductToClone] = useState<IProduct | null>(null)

  const { remove, clone } = productApi()
  const { generateQRCode } = QRGenerator()
  const { generateBarcode } = BarCodeGenerator()
  const theme = useTheme()

  const currency = useSelector(
    (state: RootStateOrAny) => state.currencyReducer.currency,
  )

  const role = getUserRole()

  const handleDeleteProduct = async () => {
    try {
      await remove([product?.id])
      displayMessage('Product deleted successfully', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleCloneProduct = async () => {
    try {
      if (!productToClone) return

      await clone(productToClone.id)

      displayMessage?.('Product created successfully!', 'success')
      success()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleQRCode = (): void => {
    generateQRCode(product.id, product.id)
  }

  const handleBarcode = (): void => {
    generateBarcode(product.id, product.id)
  }

  const getRowBackground = () => {
    if (
      !product.devices?.[0]?.location?.name &&
      !product.devices?.[0]?.locker_number
    ) {
      return parseHexToRGB('#7c7e82', '0.1')
    }
    return undefined
  }

  const data = useMemo(
    () => [
      {
        column: 'select',
        render: () =>
          role === 'admin' ? (
            <Checkbox
              checked={selected}
              onChange={(event: React.ChangeEvent, checked: boolean) => {
                handleSelectRow(event, checked, product.id)
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          ) : (
            <Checkbox
              inputProps={{ 'aria-label': 'controlled' }}
              disabled={true}
            />
          ),
      },
      {
        column: 'image',
        render: () => {
          return product.image ? (
            <Image
              src={product.image}
              alt={product?.name}
              style={{
                outline: 'none',
                borderRadius: '5px',
              }}
            />
          ) : null
        },
      },
      {
        column: 'product_name',
        render: () => {
          return role !== 'member' ? (
            <Link
              sx={{
                cursor: 'pointer',
              }}
              onClick={() => handleEditProduct(product)}
            >
              {product?.name}
            </Link>
          ) : (
            <>{product?.name}</>
          )
        },
      },
      {
        column: 'serial_number',
        render: () => {
          return product.serial_number || null
        },
      },
      {
        column: 'assigned_locker',
        render: () => {
          return product.devices?.[0]?.locker_number || '-'
        },
      },
      {
        column: 'assigned_location',
        render: () => {
          return product.devices?.[0]?.location?.name || '-'
        },
      },
      {
        column: 'cost',
        render: () => {
          return product.price ? (
            <>
              {parseCurrency(currency)}
              {product?.price}
            </>
          ) : null
        },
      },
      {
        column: 'sale_price',
        render: () => {
          return product.sales_price ? (
            <>
              {parseCurrency(currency)}
              {product?.sales_price}
            </>
          ) : null
        },
      },
      {
        column: 'sku',
        render: () => {
          return product?.sku || null
        },
      },
      {
        column: 'msrp',
        render: () => {
          return product.msrp ? (
            <>
              {parseCurrency(currency)}
              {product.msrp}
            </>
          ) : null
        },
      },
      {
        column: 'groups',
        render: () => {
          return (
            groups.find((group) => group.id === product.id_product_group)
              ?.name || null
          )
        },
      },
      {
        column: 'qr_code',
        render: () => (
          <>
            <IconButton
              onClick={handleQRCode}
              sx={{
                '&:hover': {
                  color: 'primary.main',
                },
              }}
            >
              <QrCode />
            </IconButton>
          </>
        ),
      },
      {
        column: 'barcode',
        render: () => (
          <IconButton
            onClick={handleBarcode}
            sx={{
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              width="24px"
              version="1.1"
              id="Capa_1"
              viewBox="0 0 15.273 15.273"
            >
              <g>
                <path
                  fill={theme.palette.mode === 'light' ? 'black' : 'white'}
                  d="M0,1.803h0.897V13.47H0V1.803z M1.446,13.47h0.505V1.803H1.446V13.47z M2.315,13.47h0.504V1.803   H2.315V13.47z M3.482,13.47h1.104V1.803H3.482V13.47z M5.47,13.47h0.552V1.803H5.47V13.47z M6.878,13.47h2.288V1.803H6.878V13.47z    M12.84,13.47h0.752V1.803H12.84V13.47z M14.248,1.803V13.47h1.025V1.803H14.248z M10.084,13.47h0.492V1.803h-0.492V13.47z    M11.281,13.47h0.492V1.803h-0.492V13.47z"
                />
              </g>
            </svg>
          </IconButton>
        ),
      },
      {
        column: 'action',
        render: () => (
          <>
            <IconButton
              onClick={() => {
                setProductToClone(product)
                setConfirmationMessage({
                  isOpen: true,
                  message: 'Are you sure you want to clone this product?',
                  action: 'clone',
                })
              }}
            >
              <Tooltip title="Clone">
                <ContentCopy />
              </Tooltip>
            </IconButton>

            <IconButton
              onClick={() => {
                setConfirmationMessage({
                  isOpen: true,
                  message: 'Are you sure you want to delete this product?',
                  action: 'delete',
                })
              }}
            >
              <Tooltip title="Delete">
                <DeleteForever />
              </Tooltip>
            </IconButton>
          </>
        ),
      },
    ],
    [groups, selected],
  )

  const dataMap = useMemo(() => {
    const map = new Map<string, () => React.ReactNode>()
    data.forEach((item) => {
      map.set(item.column, item.render)
    })
    return map
  }, [data])

  return (
    <>
      <TableRow
        sx={{
          background: getRowBackground(),
        }}
      >
        {filteredColumns?.map(
          (c) =>
            c.active && (
              <TableCell key={c.value} align="center">
                {dataMap.has(c.value) ? (
                  dataMap.get(c.value)!()
                ) : typeof (c as any)[c.value] === 'boolean' ? (
                  <Box
                    sx={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: (c as any)[c.value] ? 'green' : 'red',
                      display: 'inline-block',
                    }}
                  />
                ) : (
                  (product as any)[c.value] || ''
                )}
              </TableCell>
            ),
        )}
      </TableRow>
      <ConfirmDialog
        open={
          confirmationMessage.isOpen && confirmationMessage.action === 'delete'
        }
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={handleDeleteProduct}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
      <ConfirmDialog
        open={
          confirmationMessage.isOpen && confirmationMessage.action === 'clone'
        }
        message={confirmationMessage.message}
        onClose={() => setConfirmationMessage(defaultConfirmation)}
        onClickConfirm={handleCloneProduct}
        onClickCancel={() => setConfirmationMessage(defaultConfirmation)}
        confirmText="Yes"
        cancelText="No"
      />
    </>
  )
}

export default ProductRow
