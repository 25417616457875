import {
  Box,
  Typography,
  Checkbox,
  Tab,
  TextField as MUITextField,
  Autocomplete,
  Chip,
  FormControlLabel,
} from '@mui/material'
import { useEffect, useState, useMemo, ChangeEvent } from 'react'
import {
  Form,
  IDialogType,
  SelectField,
  TextField,
  Tabs,
  Image,
} from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import { notificationApi } from 'resources/notification'
import { INotification } from 'models/Notification'
import {
  EVENTS_DEFAULT_NOTIFICATIONS,
  MESSAGE_AT_LOCATION_RESTRICTIONS,
  NOTIFICATION_EVENTS_BY_MODE,
  NOTIFICATION_TYPES_BY_MODE,
  TIME_UNITS,
} from '../constants'
import { IMember } from 'models'
import { memberApi } from 'resources'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import DeleteIcon from '@mui/icons-material/Delete'
import DoneIcon from '@mui/icons-material/Done'
import useLoadingState from 'hooks/useLoadingState'
import { DropdownOption } from 'types'
import useLocations from 'hooks/useLocations'
import ImageUploader from 'components/ImageUploader/ImageUploader'

interface INotificationFormProps {
  notification: INotification | undefined
  success: () => void
  onClose: () => void
  modes: DropdownOption[]
  notificationTypes: DropdownOption[]
  displayMessage: (message: string, type?: IDialogType) => void
}

interface TeamMemberDropdownOption extends DropdownOption {
  email: string
}

const NotificationForm = ({
  notification,
  success,
  onClose,
  modes,
  notificationTypes,
  displayMessage,
}: INotificationFormProps): React.ReactElement => {
  const [filteredNotificationTypes, setFilteredNotificationTypes] = useState<
    DropdownOption[]
  >([])
  const [filteredEvents, setFilteredEvents] = useState<DropdownOption[]>([])
  const [locationsToAdd, setLocationsToAdd] = useState<string[]>([])
  const [locationsToRemove, setLocationsToRemove] = useState<string[]>([])
  const [image, setImage] = useState<File | null>(null)
  const [previewImage, setImagePreview] = useState('')
  const [teamMembers, setTeamMembers] = useState<IMember[]>([])
  const [currentTab, setCurrentTab] = useState<string>('email_template')
  const [teamMembersOptions, setTeamMembersOptions] = useState<
    TeamMemberDropdownOption[]
  >([])
  const [selectedTeamMember, setSelectedTeamMember] =
    useState<TeamMemberDropdownOption | null>(() => {
      if (notification && notification.id_member && notification.member) {
        return {
          label: notification.member.name,
          value: notification.member.user_id,
          email: notification.member.email,
        }
      }
      return null
    })
  const [before, setBefore] = useState<boolean>(
    notification ? notification?.before : false,
  )
  const [after, setAfter] = useState<boolean>(
    notification ? notification?.after : true,
  )

  const [isDefault, setIsDefault] = useState<boolean>(
    notification ? notification.is_default ?? false : false,
  )

  const { create, update } = notificationApi()
  const { getMany: getTeamMembers } = memberApi()

  const { loading, setLoading } = useLoadingState()
  const { loading: loadingTeamMembers, setLoading: setLoadingTeamMembers } =
    useLoadingState()

  const { loadingLocations, locationsOptions, locations } = useLocations()

  const methods = useForm<INotification>({
    defaultValues: {
      ...notification,
    },
  })

  const mode = methods.watch('mode')
  const timeUnit = methods.watch('time_unit')
  const notificationType = methods.watch('notification_type')
  const nameVal = methods.watch('name')
  const timeAmountVal = methods.watch('time_amount')

  const filteredLocations = useMemo(() => {
    if (notification) {
      return locationsOptions.filter(
        (option) => !notification.locations.some((l) => l.id == option.value),
      )
    }
    return locationsOptions
  }, [locationsOptions])

  const alreadyHasDefault = false // TODO: Implement this

  const [headerImage, setHeaderImage] = useState<File | null>(null)
  const [footerImage, setFooterImage] = useState<File | null>(null)
  const [bodyImage, setBodyImage] = useState<File | null>(null)

  const [email, setEmail] = useState<boolean>(
    notification ? notification?.email ?? false : false,
  )
  const [sms, setSms] = useState<boolean>(
    notification ? notification?.sms ?? true : true,
  )

  const [headerImageSuccess, setHeaderImageSuccess] = useState<boolean>(
    notification?.header_s3_url ? true : false,
  )
  const [bodyImageSuccess, setBodyImageSuccess] = useState<boolean>(
    notification?.body_s3_url ? true : false,
  )
  const [footerImageSuccess, setFooterImageSuccess] = useState<boolean>(
    notification?.footer_s3_url ? true : false,
  )

  const hideMessageAtLocation =
    locationsToAdd.length > 0 ||
    (notification && notification?.locations.length > 0) ||
    MESSAGE_AT_LOCATION_RESTRICTIONS[mode]?.includes(notificationType)

  const handleSubmit = async (data): Promise<void> => {
    if (sms && !data.message) {
      displayMessage('Please fill out the SMS template message', 'error')
      setCurrentTab('sms_template')
      return
    }

    if (email && !data.message_email) {
      displayMessage('Please fill out the Email template message', 'error')
      setCurrentTab('email_template')
      return
    }

    if (isDefault && alreadyHasDefault && !notification?.is_template) {
      displayMessage(
        'A default notification for this event combination already exists. Only one default is allowed.',
        'error',
      )
      return
    }

    const locationsArr = !notification
      ? [...locationsToAdd]
      : [
          ...locationsToAdd,
          ...notification?.locations
            .filter((location) => !locationsToRemove.includes(location.id))
            .map((location) => location.id),
        ]

    const cleanTimeAmount = data.time_amount
      ? Number(String(data.time_amount).replace(/\D/g, ''))
      : 0

    const newNotification = {
      ...data,
      locations: isDefault ? [] : locationsArr,
      is_default: isDefault,
      time_amount: cleanTimeAmount,
      before,
      after,
      email,
      sms,
      ...(selectedTeamMember && { id_member: selectedTeamMember.value }),
      location_message:
        isDefault || hideMessageAtLocation ? '' : data.location_message || '',
    }

    try {
      setLoading(true)
      if (notification) {
        await update(notification.id, newNotification, {
          header: headerImage || undefined,
          body: bodyImage || undefined,
          footer: footerImage || undefined,
        })
        onClose()
        displayMessage('Notification updated successfully', 'success')
        success()
      } else {
        await create(newNotification, {
          header: headerImage || undefined,
          body: bodyImage || undefined,
          footer: footerImage || undefined,
        })
        onClose()
        displayMessage('Notification created successfully', 'success')
        success()
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const fetchTeamMembers = async (): Promise<void> => {
    try {
      setLoadingTeamMembers(true)
      const teamMembers = await getTeamMembers(1, 150, '')
      setTeamMembers(teamMembers.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleToggleWhen = (event) => {
    if (event.target.checked) {
      setBefore((previousValue) => !previousValue)
      setAfter((previousValue) => !previousValue)
    }
  }

  const handleToggle = (event: React.SyntheticEvent, value: any) => {
    const locationsSelected: string[] = value.map((option) => option.value)
    setLocationsToAdd(locationsSelected)
  }

  const handleMemberSelection = (
    event: React.SyntheticEvent,
    value: TeamMemberDropdownOption | null,
  ) => {
    setSelectedTeamMember(value)
  }

  const buildMembersOptions = () => {
    if (teamMembers.length > 0) {
      const autoCompleteOptions = teamMembers.map((member) => ({
        label: member.name,
        value: member.user_id,
        email: member.email,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setTeamMembersOptions(sortedOptions)
    }
  }

  const handleSelect = (locationId: string) => {
    if (locationsToRemove.includes(locationId)) {
      setLocationsToRemove(locationsToRemove.filter((id) => id !== locationId))
    } else {
      setLocationsToRemove((prevState) => [...prevState, locationId])
    }
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue)
  }

  const handleHeaderImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setHeaderImage(e.target.files[0])
      setHeaderImageSuccess(true)
    }
  }

  const handleBodyImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setBodyImage(e.target.files[0])
      setBodyImageSuccess(true)
    }
  }

  const handleFooterImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFooterImage(e.target.files[0])
      setFooterImageSuccess(true)
    }
  }

  useEffect(() => {
    if (mode) {
      const filterMode = mode === 'service' ? mode : 'all-modes'
      const filteredTypes = notificationTypes.filter((type) =>
        NOTIFICATION_TYPES_BY_MODE[filterMode].includes(type.value),
      )
      setFilteredNotificationTypes(filteredTypes)
      setFilteredEvents(NOTIFICATION_EVENTS_BY_MODE[mode])
    }
  }, [mode])

  useEffect(() => {
    if (timeUnit === 'immediately') {
      methods.setValue('time_amount', 0)
    }
  }, [timeUnit])

  useEffect(() => {
    setLoadingTeamMembers(false)
  }, [teamMembersOptions])

  useEffect(() => {
    buildMembersOptions()
  }, [locations, teamMembers])

  useEffect(() => {
    fetchTeamMembers()
  }, [])

  useEffect(() => {
    const initializeSelectedMember = () => {
      if (notification?.id_member && teamMembers.length > 0) {
        const member = teamMembers.find(
          (m) => m.user_id === notification.id_member,
        )
        if (member) {
          setSelectedTeamMember({
            label: member.name,
            value: member.user_id,
            email: member.email,
          })
        }
      }
    }

    initializeSelectedMember()
  }, [notification, teamMembers])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const ShowAssignTeamMember =
    notificationType === 'non_locker_delivery' ||
    notificationType === 'on_expired' ||
    notificationType === 'on_service_pickup'

  const ShowEmailTemplate = currentTab === 'email_template'

  const timingPhrase = useMemo(() => {
    if (timeUnit === 'immediately') {
      return 'immediately after'
    }

    // Extract only numbers from timeAmountVal and convert to number
    const numericValue = String(timeAmountVal).replace(/\D/g, '')
    const amount = numericValue ? parseInt(numericValue) : 0

    return `${amount} ${timeUnit} after`
  }, [timeUnit, timeAmountVal])

  const eventNameFormatted = notificationType
    ? notificationType.replace(/_/g, ' ')
    : ''

  const notificationDescription = nameVal
    ? `${nameVal} notification will be sent ${timingPhrase} the ${eventNameFormatted} event occurs.`
    : ''

  const showLocationsMessage =
    methods.watch('message') || methods.watch('message_email')
  const messageTooltip =
    mode === 'delivery' && notificationType === 'on_start'
      ? 'If you are using Kiosk use ((event code)) for the user pickup event. Without this you cannot complete a transaction. If you are using web app- you don’t need an event code to do a transaction'
      : ''

  useEffect(() => {
    const defaultMessage =
      EVENTS_DEFAULT_NOTIFICATIONS[mode]?.[notificationType] || ''
    methods.setValue('message', defaultMessage)
    methods.setValue('message_email', defaultMessage)
  }, [notificationType, mode])

  const TabForm = (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit}>
          <FormWrapper
            title={notification ? 'Edit Notification' : 'Add Notification'}
          >
            <SelectField
              name="mode"
              label="Mode"
              items={modes}
              isReadOnly={notification && notification.is_template}
              rules={{ required: 'Mode is required' }}
            />

            {mode && (
              <TextField
                name="name"
                label="Name"
                rules={{ required: 'Name is required' }}
              />
            )}

            {nameVal && (
              <>
                <Typography variant="h5">When?</Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '10px',
                  }}
                >
                  {nameVal && (
                    <SelectField
                      name="notification_type"
                      label="Event"
                      items={filteredEvents}
                      isReadOnly={notification && notification.is_template}
                    />
                  )}

                  {notificationType && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        gap: '10px',
                      }}
                    >
                      {timeUnit !== 'immediately' && (
                        <TextField
                          name="time_amount"
                          label="Duration"
                          onlyInteger={true}
                          rules={{
                            required: 'Duration is required',
                            min: {
                              value: timeUnit === 'immediately' ? 0 : 1,
                              message: 'Duration has to be greater than 0',
                            },
                          }}
                        />
                      )}
                      <SelectField
                        name="time_unit"
                        label="Unit"
                        items={TIME_UNITS}
                        rules={{ required: 'Unit is required' }}
                      />
                    </Box>
                  )}

                  {nameVal && notificationType && timeUnit && (
                    <Typography color="text.secondary">
                      {notificationDescription}
                    </Typography>
                  )}
                </Box>
              </>
            )}

            {notificationType && nameVal && (
              <Box sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDefault}
                      onChange={(e) => setIsDefault(e.target.checked)}
                    />
                  }
                  label="Set as Default Notification"
                />
                <Typography variant="caption" color="text.secondary">
                  Only one default notification is allowed per mode & event
                  combination.
                </Typography>
              </Box>
            )}

            {timeUnit &&
              (timeAmountVal || timeAmountVal === 0) &&
              nameVal &&
              notificationType && (
                <>
                  {ShowEmailTemplate && (
                    <>
                      <ImageUploader
                        inputId="header-uploader"
                        buttonText={
                          headerImageSuccess || notification?.header_s3_url
                            ? '✓ Header Image Uploaded'
                            : 'Upload Header Image'
                        }
                        helpText="Upload a header image here."
                        onChange={handleHeaderImageUpload}
                      />

                      <TextField
                        name="header"
                        label="Text for header"
                        multiline={true}
                      />

                      <ImageUploader
                        inputId="body-uploader"
                        buttonText={
                          bodyImageSuccess || notification?.body_s3_url
                            ? '✓ Body Image Uploaded'
                            : 'Upload Body Image'
                        }
                        helpText="You can upload a logo image here. This image will be used above the message text in the email."
                        onChange={handleBodyImageUpload}
                      />
                      <TextField
                        name="message_email"
                        label="Message"
                        tooltip={messageTooltip}
                        multiline={true}
                        rules={{ required: 'Message is required' }}
                      />
                    </>
                  )}

                  {ShowEmailTemplate && (
                    <>
                      <ImageUploader
                        inputId="footer-uploader"
                        buttonText={
                          footerImageSuccess || notification?.footer_s3_url
                            ? '✓ Footer Image Uploaded'
                            : 'Upload Footer Image'
                        }
                        helpText="Upload a footer image here."
                        onChange={handleFooterImageUpload}
                      />
                      <TextField
                        name="footer"
                        label="Text for footer"
                        multiline={true}
                      />
                    </>
                  )}
                  {!ShowEmailTemplate && (
                    <>
                      <TextField
                        name="message"
                        tooltip={messageTooltip}
                        label="Message"
                        multiline={true}
                        rules={{ required: 'Message is required' }}
                      />
                    </>
                  )}
                </>
              )}

            {!isDefault && notificationType && showLocationsMessage && (
              <>
                <Typography variant="h5">Assign Locations</Typography>
                <Autocomplete
                  multiple
                  options={filteredLocations}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.label}
                    </li>
                  )}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <MUITextField {...params} label="Search locations..." />
                  )}
                  onChange={handleToggle}
                  loading={loadingLocations}
                  loadingText={'Loading locations...'}
                />

                {!hideMessageAtLocation && (
                  <TextField
                    name="location_message"
                    label="Message at location"
                    placeholder=" Message at location"
                  />
                )}

                {notification && (
                  <Typography variant="h5">Locations assigned</Typography>
                )}
                {notification && (
                  <Typography variant="caption" color="rgb(173, 176, 187)">
                    {notification.locations.length > 0
                      ? 'Select the locations that you want to remove and hit submit'
                      : 'No locations assigned'}
                  </Typography>
                )}
                {notification && notification?.locations.length > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      gap: '0.75rem',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {notification.locations.map((location) => (
                      <Chip
                        key={location.id}
                        label={location.name}
                        onClick={() => handleSelect(location.id)}
                        onDelete={() => handleSelect(location.id)}
                        deleteIcon={
                          locationsToRemove.includes(location.id) ? (
                            <DoneIcon />
                          ) : (
                            <DeleteIcon />
                          )
                        }
                        variant={
                          locationsToRemove.includes(location.id)
                            ? 'filled'
                            : 'outlined'
                        }
                        color={
                          locationsToRemove.includes(location.id)
                            ? 'primary'
                            : 'default'
                        }
                      />
                    ))}
                  </Box>
                )}
              </>
            )}

            {timeUnit &&
              (timeAmountVal || timeAmountVal === 0) &&
              nameVal &&
              notificationType &&
              methods.watch('message') &&
              ShowAssignTeamMember && (
                <>
                  <Typography variant="h5">Assign Team Member</Typography>
                  <Autocomplete
                    value={selectedTeamMember}
                    options={teamMembersOptions}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Typography>{option.label}</Typography>
                          <Typography color="text.secondary">
                            {option.email}
                          </Typography>
                        </Box>
                      </li>
                    )}
                    style={{ width: '100%' }}
                    renderInput={(params) => (
                      <MUITextField {...params} label="Team member..." />
                    )}
                    onChange={handleMemberSelection}
                    loading={loadingTeamMembers}
                    loadingText={'Loading team members...'}
                    disableCloseOnSelect={true}
                    getOptionLabel={(option) => option.label}
                  />
                </>
              )}
            {selectedTeamMember && (
              <TextField
                name="member_message"
                label="Message for team member"
                placeholder="Message for team member"
              />
            )}

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )

  return (
    <>
      <Tabs
        tabs={[
          {
            label: 'Email template',
            value: 'email_template',
            children: TabForm,
          },
          {
            label: 'SMS template',
            value: 'sms_template',
            children: TabForm,
          },
        ]}
        currentTab={currentTab}
        handleChange={handleTabChange}
      />
    </>
  )
}

export default NotificationForm
