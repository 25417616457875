import { ISettings } from 'models/Settings'
import { useEffect, useState } from 'react'
import { settingsApi } from 'resources'
import useDialog from './useDialog'

const useOrgSettings = () => {
  const [orgSettings, setOrgSettings] = useState<Partial<ISettings>>({})
  const { getSettings, getWhiteLabel } = settingsApi()
  const { displayMessage } = useDialog()

  const fetchOrgSettings = async () => {
    try {
      const settings: ISettings = await getSettings()
      const whitelabel = await getWhiteLabel()
      settings.terms_condition = whitelabel?.terms_condition
      settings.terms_condition_2nd = whitelabel?.terms_condition_2nd
      settings.terms_name_2nd = whitelabel?.terms_name_2nd
      localStorage.setItem('settings', JSON.stringify(settings))
      setOrgSettings(settings)
    } catch (error) {
      displayMessage(`${(error as Error).message}`)
    }
  }

  useEffect(() => {
    fetchOrgSettings()
  }, [])

  return [orgSettings, fetchOrgSettings] as const
}

export default useOrgSettings
