import { Box, Typography, Checkbox, useMediaQuery, Theme } from '@mui/material'

import { Form, TextField, SelectField, IDialogType } from 'components'
import { FormProvider, useForm } from 'react-hook-form'
import { settingsApi, sizeApi } from 'resources'
import { PARCEL_EXPIRATION_UNITS } from 'pages/Settings/constants'
import { ISettings } from 'models/Settings'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import useLoadingState from 'hooks/useLoadingState'
import { useEffect, useState } from 'react'
import useHardwareTypes from 'hooks/useHardwareTypes'
import { DropdownOption } from 'types'
import useDeviceModes from 'hooks/useDeviceModes'
import { Help } from 'components/Help'

interface ISettingsFormProps {
  orgSettings: Partial<ISettings>
  fetchOrgSettings: () => void
  displayMessage: (message: string, type?: IDialogType) => void
}

const SettingsForm = ({
  orgSettings,
  fetchOrgSettings,
  displayMessage,
}: ISettingsFormProps) => {
  const [unlimited, setUnlimited] = useState<boolean>(() => {
    if (orgSettings.default_max_reservations) {
      return false
    }
    return true
  })

  const [secondTermsAndConditions, setSecondTermsAndConditions] =
    useState<boolean>(Boolean(orgSettings.terms_condition_2nd) || false)
  const [expiredParcels, setExpiredParcels] = useState<boolean>(
    Boolean(
      orgSettings.parcel_expiration || orgSettings.parcel_expiration_unit,
    ),
  )

  const [secondContact, setSecondContact] = useState<boolean>(
    orgSettings.new_contact_notification_enabled || false,
  )
  const [verificationEmailSent, setVerificationEmailSent] =
    useState<boolean>(false)
  const [emailVerified, setEmailVerified] = useState<boolean>(
    Boolean(orgSettings.new_contact_notification_email_verified) || false,
  )

  const settings = JSON.parse(localStorage.getItem('settings') || '{}')

  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const { createSettings, updateSettings, patchWhiteLabel } = settingsApi()
  const { orgHardware } = useHardwareTypes()

  const methods = useForm<ISettings>({
    defaultValues: {
      ...orgSettings,
    },
  })

  const { loading, setLoading } = useLoadingState()
  const { getMany: getSizes } = sizeApi()
  const { modes } = useDeviceModes()

  const [sizes, setSizes] = useState<DropdownOption[]>([])

  const fetchSizes = async () => {
    try {
      const sizes = await getSizes(1, 10000)
      setSizes(
        sizes.items.map((size) => ({
          value: size.id,
          label: size.name,
        })),
      )
    } catch (error) {
      displayMessage?.((error as Error).message)
    }
  }

  const onSubmit = async (newLocker: ISettings) => {
    try {
      setLoading(true)
      const reservations = unlimited ? null : newLocker.default_max_reservations
      if (settings) {
        const updatedSettings = {
          ...settings,
          ...newLocker,
          default_max_reservations: reservations,
          new_contact_notification_enabled: secondContact,
        }
        await updateSettings(updatedSettings)

        if (
          !secondTermsAndConditions &&
          newLocker.terms_condition_2nd &&
          newLocker.terms_name_2nd
        ) {
          await patchWhiteLabel({
            terms_condition_2nd: null,
            terms_name_2nd: null,
          })
        }
        if (
          newLocker.terms_condition &&
          settings.terms_condition !== newLocker.terms_condition
        ) {
          await patchWhiteLabel({
            terms_condition: newLocker.terms_condition,
          })
        }
        if (
          newLocker.terms_condition_2nd &&
          (settings.terms_condition_2nd !== newLocker.terms_condition_2nd ||
            settings.terms_name_2nd !== newLocker.terms_name_2nd)
        ) {
          await patchWhiteLabel({
            terms_condition_2nd: newLocker.terms_condition_2nd,
            terms_name_2nd: newLocker.terms_name_2nd,
          })
        }

        displayMessage('Settings updated successfully', 'success')
        fetchOrgSettings()
      } else {
        await createSettings({
          ...newLocker,
          default_max_reservations: reservations,
          invoice_prefix: 'ZTT',
        })
        displayMessage('Settings created successfully', 'success')

        if (newLocker.terms_condition) {
          await patchWhiteLabel({
            terms_condition: newLocker.terms_condition,
          })
        }
        if (newLocker.terms_condition_2nd || newLocker.terms_name_2nd) {
          await patchWhiteLabel({
            terms_condition_2nd: newLocker.terms_condition_2nd || null,
            terms_name_2nd: newLocker.terms_name_2nd || null,
          })
        }
        fetchOrgSettings()
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`)
      methods.reset()
    } finally {
      setLoading(false)
    }
  }

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  useEffect(() => {
    fetchSizes()
  }, [])

  useEffect(() => {
    if (orgHardware.length === 1) {
      methods.setValue('default_device_hardware', orgHardware[0].value)
    }

    if (modes.length === 1) {
      methods.setValue('default_device_mode', modes[0].value)
    }
  }, [orgHardware, modes])

  useEffect(() => {
    const duration = methods.watch('parcel_expiration')
    const unit = methods.watch('parcel_expiration_unit')
    if (!expiredParcels && (unit || duration)) {
      methods.setValue('parcel_expiration', null)
      methods.setValue('parcel_expiration_unit', null)
    }
  }, [expiredParcels])

  const notification_types = [
    { label: 'Email', value: 'email' },
    { label: 'SMS', value: 'sms' },
  ]

  return (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
              whiteSpace: 'nowrap',
              width: smDown ? '100%' : 400,
            }}
          >
            <FormWrapper>
              <Typography textAlign="center" variant="h3">
                Edit Support
              </Typography>

              <TextField
                name="default_support_phone"
                label="Phone Number"
                placeholder="Phone Number"
                rules={{
                  pattern: {
                    value: /^\+[1-9]/i,
                    message: 'Please enter country code',
                  },
                  validate: (value: string) =>
                    !isNaN(Number(value.replace('+', ''))) ||
                    'Invalid phone number',
                }}
              />
              <TextField
                name="default_support_email"
                label="Email"
                placeholder="Email"
                rules={{
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
              />

              <Typography textAlign="center" variant="h3">
                Edit Terms and Conditions
              </Typography>
              <TextField
                name="terms_condition"
                label="Terms and Conditions URL"
                placeholder="Terms and Conditions URL"
              />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={secondTermsAndConditions}
                  onChange={() =>
                    setSecondTermsAndConditions(
                      (previousValue) => !previousValue,
                    )
                  }
                />
                <Help helpText="You can add another terms and conditions here and label it.">
                  <Typography variant="h5">
                    Add another terms and conditions
                  </Typography>
                </Help>
              </Box>

              {secondTermsAndConditions && (
                <>
                  <TextField
                    name="terms_name_2nd"
                    label="Name"
                    placeholder="Secondary Terms and Conditions Name"
                  />
                  <TextField
                    name="terms_condition_2nd"
                    label="Secondary Terms and Conditions URL"
                    placeholder="Secondary Terms and Conditions URL"
                  />
                </>
              )}
              <Typography variant="h5">Expired Parcels</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={expiredParcels}
                  onChange={() =>
                    setExpiredParcels((previousValue) => !previousValue)
                  }
                />
                <Typography variant="h5">
                  Set duration for expiry of parcels
                </Typography>
              </Box>

              {expiredParcels && (
                <Box sx={{ display: 'flex', gap: '0.5rem' }}>
                  <TextField
                    name="parcel_expiration"
                    label="Duration"
                    placeholder="Duration"
                    rules={{
                      required: 'A number is required',
                    }}
                    onlyInteger={true}
                  />
                  <SelectField
                    name="parcel_expiration_unit"
                    label="Unit"
                    placeholder="Unit"
                    items={PARCEL_EXPIRATION_UNITS}
                  />
                </Box>
              )}

              <FormActions
                cancelText="Clear"
                onClose={() => {
                  methods.reset()
                }}
                loading={loading}
                disableSubmit={disableSubmit}
              />
            </FormWrapper>
          </Box>
        </Form>
      </FormProvider>
    </>
  )
}
export default SettingsForm
