export const EVENTS_DEFAULT_NOTIFICATIONS: Record<
  string,
  Record<string, string>
> = {
  service: {
    on_start:
      'Your order is ready for pickup in ((locker_number)) at ((location_name)). Please collect it at your earliest convenience.',
    on_service_dropoff:
      'Order ((order_id)) has been received and is awaiting operator pickup in Locker ((locker_number)) at ((location_name)).\n\nWe will update you when the status of your order has changed.',
    on_service_pickup:
      "Good news! Your items has been picked up and is being processed for ((order_id)).\n\nWe'll soon weigh it and let you know how much will be charged to your card.",
    on_service_charge:
      'Your items weigh ((weight)) lbs and you were successfully charged $((charged_amount)) for order ((order_id)).\n\nYour order is in process and we will notify you when it’s available to be for pick up.',
    on_reservation:
      'Thank you for using our service. Your reservation is confirmed.',
    on_complete:
      'Order ((order_id)) has been completed.\nThank you for trusting us. Please return soon!',
  },
  rental: {
    on_start:
      'Order ((order_id)) has started in locker ((locker_number)) at ((location_address))',
    on_reservation: 'You have a reservation for Asset at ((location_name)).',
    on_complete:
      'Order ((order_id)) has successfully been completed.\nThank you for using our service. Please return soon!',
  },
  storage: {
    on_start:
      'Order ((order_id)) has started in locker ((locker_number)) at ((location_address))',
    on_reservation: 'You have a reservation for Storage at ((location_name)).',
    on_complete:
      'Order ((order_id)) has successfully been completed.\nThank you for trusting us. Please return soon!',
  },
  delivery: {
    on_start:
      'Your transaction has started in locker ((locker_number)) at ((location_address))',
    on_reservation: 'You have a reservation for Delivery at ((location_name)).',
    on_complete:
      'Order ((order_id)) has successfully been picked up.\nThank you. Please return soon!',
    non_locker_delivery:
      'Your package did not fit in a locker. It was delivered at the front desk at ((location_name)). Please collect it at the earliest.',
    on_expired:
      'Your parcel is going to expire in ((selected_duration)) from ((locker_number)) at ((location_name)). If you do not collect your parcel before it expires you can collect it from the front desk.',
  },
}

export const NOTIFICATIONS_TABLE_HEADERS = [
  {
    value: 'name',
    label: 'Name',
    active: true,
  },
  {
    value: 'type',
    label: 'Type',
    active: true,
  },
  {
    value: 'email',
    label: 'Email',
    active: true,
  },
  {
    value: 'sms',
    label: 'SMS',
    active: true,
  },
  {
    value: 'push',
    label: 'Push',
    active: true,
  },
  {
    value: 'action',
    label: 'Action',
    active: true,
  },
]

export const TIME_UNITS = [
  { value: 'immediately', label: 'Immediately' },
  { value: 'minute', label: 'Minute' },
  { value: 'hour', label: 'Hour' },
  { value: 'day', label: 'Day' },
  { value: 'week', label: 'Week' },
]

export const NOTIFICATION_TYPES_BY_MODE = {
  'all-modes': ['welcome', 'on_start', 'reminder', 'complete'],
  delivery: ['welcome', 'on_start', 'reminder', 'complete', 'on_expired'],
  service: [
    'welcome',
    'start',
    'pickup',
    'charge',
    'user_pickup',
    'reminder',
    'complete',
  ],
}

export const NOTIFICATION_EVENTS_BY_MODE = {
  service: [
    {
      value: 'on_start',
      label: 'User pick up',
    },
    {
      value: 'on_service_dropoff',
      label: 'Ready for pickup',
    },
    {
      value: 'on_service_pickup',
      label: 'Operator Pickup',
    },
    {
      value: 'on_service_charge',
      label: 'Charged',
    },
    {
      value: 'on_reservation',
      label: 'Reservation',
    },
    {
      value: 'on_complete',
      label: 'Order Completion',
    },
  ],
  rental: [
    {
      value: 'on_start',
      label: 'Asset Start',
    },
    {
      value: 'on_reservation',
      label: 'Reservation',
    },
    {
      value: 'on_complete',
      label: 'Asset End',
    },
  ],
  storage: [
    {
      value: 'on_start',
      label: 'Order Start',
    },
    {
      value: 'on_reservation',
      label: 'Reservation',
    },
    {
      value: 'on_complete',
      label: 'Order Completion',
    },
  ],
  delivery: [
    {
      value: 'on_start',
      label: 'User pick up',
    },
    {
      value: 'on_reservation',
      label: 'Reservation',
      note: 'For Kiosk only',
    },
    {
      value: 'on_complete',
      label: 'Order Completion',
    },
    {
      value: 'non_locker_delivery',
      label: 'Message for delivered but not in the locker',
      note: 'For Kiosk only',
    },
    {
      value: 'on_expired',
      label: 'Expired Parcels',
      note: 'For Kiosk only',
    },
  ],
}

export const MESSAGE_AT_LOCATION_RESTRICTIONS = {
  rental: ['on_service_pickup', 'on_start', 'on_reservation', 'on_complete'],
  delivery: [
    'on_service_pickup',
    'on_start',
    'on_reservation',
    'on_complete',
    'on_expired',
  ],
  service: [
    'on_service_pickup',
    'on_start',
    'on_service_dropoff',
    'on_service_charge',
    'on_reservation',
    'on_complete',
  ],
  storage: ['on_service_pickup', 'on_start'],
}
