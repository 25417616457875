import { ISession } from 'models/Session'
import { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { setUserSession } from 'redux/user-session/Action'

export const useUserSession = () => {
  const session = useSelector(
    (state: RootStateOrAny) => state.userSessionReducer,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    const getSession = async () => {
      const userSession: ISession = JSON.parse(
        localStorage.getItem('userSession') || '{}',
      )
      dispatch(setUserSession(userSession))
    }
    getSession()
  }, [])

  return {
    session,
    isHelpDesk: session.email.includes('helpdesk') && !session.name,
  }
}
