import { useApiRequest } from 'utils'
import { IProduct, IProductList } from 'models/Product'
import { IProductGroup, IProductGroupList } from 'models/ProductGroup'
import { getErrorMessage } from 'utils/helpers'

const productGroupApi = () => {
  const { fetchPartnerEndpoint } = useApiRequest()

  const getMany = async (
    page: number,
    size: number,
    search: string = '',
  ): Promise<IProductGroupList> => {
    const urlParams = new URLSearchParams({
      page: page.toString(),
      size: size.toString(),
      search,
    })
    const endpoint = `product-groups?${urlParams.toString()}`
    const method = 'GET'
    try {
      const response = await fetchPartnerEndpoint({ endpoint, method })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const create = async (group): Promise<IProductGroup> => {
    const endpoint = `product-groups`
    const method = 'POST'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: group,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const update = async (
    groupId: string,
    group: any,
  ): Promise<IProductGroup> => {
    const endpoint = `product-groups/${groupId}`
    const method = 'PUT'

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        body: group,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  const remove = async (ids: string[]): Promise<IProductGroup> => {
    const endpoint = `product-groups`
    const method = 'DELETE'
    const headers = {
      'Content-Type': 'application/json',
    }
    const body = JSON.stringify(ids)

    try {
      const response = await fetchPartnerEndpoint({
        endpoint,
        method,
        headers,
        body,
      })
      if (!response.ok) {
        const result = await response.json()
        throw result.detail
      }
      return response.json()
    } catch (error) {
      throw new Error('Something went wrong! ' + getErrorMessage(error))
    }
  }

  return { getMany, create, update, remove }
}
export default productGroupApi
