import {
  Button,
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import { DEFAULT_PAGE, DEVICES_PER_PAGE } from 'constants/pagination'
import useDialog from 'hooks/useDialog'
import { ISize, ISizeList } from 'models'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { sizeApi } from 'resources'
import SizeRow from './SizeRow'
import SizeForm from './SizeForm'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useSortableHeader from 'hooks/useSortableHeader'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import LoadingTableData from 'components/Table/components/LoadingTableData/LoadingTableData'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import Toolbar from 'components/Toolbar/Toolbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'

const Sizes = () => {
  const [sizes, setSizes] = useState<ISizeList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [sizesBackup, setSizesBackup] = useState<ISize[]>([])
  const [currentSize, setCurrentSize] = useState<ISize | undefined>()
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEVICES_PER_PAGE)
  const [search, setSearch] = useState<string>('')
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([])

  const { getMany: getSizes, remove } = sizeApi()
  const { dialog, displayMessage, closeDialog } = useDialog()
  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({ displayMessage })

  const { loading, setLoading } = useLoadingState(true)

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'sizes',
      nestedProps: [],
    })

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )
    setSelectedRowsData((previousValue) =>
      checked
        ? [
            ...previousValue,
            sizes.items.find((size) => size.id === id) as ISize,
          ]
        : previousValue.filter((row) => row.id !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? sizes.items.map((device) => device.id) : [],
    )
    setSelectedRowsData(checked ? sizes.items : [])
  }

  const handleDeleteMany = async () => {
    try {
      await remove(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchSizes()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleEditSize = (size: ISize) => {
    setCurrentSize(size)
    setOpenSidebar(true)
  }

  const handleCloseSidebar = () => {
    setOpenSidebar(false)
    setCurrentSize(undefined)
  }

  const fetchSizes = async (
    showNewestFirst: boolean = false,
  ): Promise<void> => {
    try {
      setLoading(true)
      const sizes = await getSizes(currentPage + 1, rowsPerPage, search)
      setSizesBackup([...sizes.items])
      setSizes(sizes)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchSizes()
  }, [search, currentPage, rowsPerPage])

  useEffect(() => {
    fetchSizes()
    getDefaultFilters()
  }, [])

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(sizes.items, sizesBackup),
    [sizes, order, orderBy],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          disabled={loading}
          variant="contained"
          name="addSize"
          onClick={() => setOpenSidebar(true)}
        >
          Add Size
        </Button>
      ),
    },
  ]

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          storageKey="filteredColumns_sizes"
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelected={selectedRows.length}
          itemsSelectedData={selectedRowsData}
          handleAction={handleDeleteMany}
          actionsAllowed={['delete', 'export']}
          model="size"
        />
      )}
      {!loading && sizes.items.length > 0 && (
        <>
          <Table>
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={filteredColumns.filter((c) => c.active)}
              handleSelectAll={handleSelectAll}
            />
            <TableBody>
              {visibleRows.map((size) => (
                <SizeRow
                  key={size.id}
                  size={size}
                  success={fetchSizes}
                  displayMessage={displayMessage}
                  filteredColumns={filteredColumns}
                  handleEditSize={handleEditSize}
                  handleSelectRow={handleSelectRow}
                  selected={selectedRows.includes(size.id)}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            totalItems={sizes.total}
            currentPage={currentPage}
            itemsPerPage={rowsPerPage}
            setCurrentPage={setCurrentPage}
            setItemsPerPage={setRowsPerPage}
          />
        </>
      )}

      <LoadingTableData isLoading={loading} />

      <NoData condition={!loading && sizes.items.length === 0} />

      <SideBar open={openSidebar} onClose={handleCloseSidebar}>
        <SizeForm
          size={currentSize}
          success={fetchSizes}
          onClose={handleCloseSidebar}
          displayMessage={displayMessage}
        />
      </SideBar>

      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}

export default Sizes
