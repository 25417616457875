/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { ISize, IPrice, ILocation, IUser, IGroup, ITransaction } from 'models'
import { IProduct } from './Product'

export enum DeviceType {
  linka = 'linka',
  spintly = 'spintly',
  ojmar = 'ojmar',
  gantner = 'gantner',
  keynius = 'keynius',
  harbor = 'harbor',
  dclock = 'dclock',
  kerong = 'kerong',
  headleader = 'headleader',
}

export enum DeviceMode {
  service = 'service',
  storage = 'storage',
  rental = 'rental',
  delivery = 'delivery',
  vending = 'vending',
}

export interface IDevice {
  id: string
  created_at: string
  name: string
  item: string
  item_description: string
  image: string
  locker_number: number
  price_required: boolean
  mode: string
  status: string
  hardware_type: DeviceType
  mac_address: string
  integration_id: string
  locker_udn: string
  user_code: string
  master_code: string
  gantner_id: string
  keynius_id: string
  lock_status: string
  harbor_tower_id: string
  harbor_locker_id: string
  dclock_terminal_no: string
  dclock_box_no: string
  id_location: string
  id_size: string
  id_price: string | null
  id_product: string | null
  location: ILocation
  size: ISize
  price: IPrice
  product: IProduct
  shared: boolean
  require_image: boolean
  restriction: IRestriction | null
  ip: string | null
  circuit_unit: string
  board_unit: string
  hook_port: number | null
  additional_metadata: string | null
  custom_identifier: string | null
  port: number | null
  headboard: number | null
  ada_compliance: boolean
}

interface IRestriction {
  restriction_type: string
  items: IUser[] | IGroup[]
}

export interface IDeviceLog {
  id: string
  log_type: DeviceLogType
  log_owner: string
  event: ITransaction
  created_at: Date | string
}

export type DeviceLogType = 'lock' | 'unlock' | 'maintenance' | 'report_issue'

export interface IDeviceList {
  items: IDevice[]
  total: number
  pages: number
}
