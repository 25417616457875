import {
  Box,
  Divider,
  Menu,
  Skeleton,
  Switch,
  TableCell,
  Typography,
  TableRow,
  Autocomplete,
  TextField,
} from '@mui/material'
import {
  Button,
  NotificationDialog,
  SideBar,
  Table,
  TableBody,
  TablePagination,
} from 'components'
import { DEFAULT_PAGE, TRANSACTIONS_PER_PAGE } from 'constants/pagination'
import {
  IDevice,
  IPrice,
  ISize,
  ITransaction,
  ITransactionList,
  IUser,
} from 'models'
import { useEffect, useMemo, useState } from 'react'
import {
  deviceApi,
  organizationApi,
  priceApi,
  sizeApi,
  transactionsApi,
} from 'resources'
import { TRANSACTION_TYPES } from '../../constants'
import TransactionRow from './TransactionRow'
import UserForm from 'pages/People/components/users/UserForm'
import DeviceForm from 'pages/Doors/components/devices/DeviceForm'
import TransactionForm from './TransactionForm'
import useDialog from 'hooks/useDialog'
import { API_URL } from '../../../../constants'
import {
  EVENT_DEFAULTS,
  EVENT_SHORT_DEFAULTS,
  EVENT_STATUS_OPTIONS,
} from '../../constants'
import {
  getOrganizationFromHost,
  getPaginatedSlice,
  generateFilters,
  getStoredTableFilters,
} from 'utils/helpers'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useSortableHeader from 'hooks/useSortableHeader'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import useDeviceModes from 'hooks/useDeviceModes'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'
import useLocations from 'hooks/useLocations'

const Transactions = () => {
  const [transactions, setTransactions] = useState<ITransactionList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [transactionsBackup, setTransactionsBackup] = useState<ITransaction[]>(
    [],
  )
  const [action, setAction] = useState<string>('')
  const [devices, setDevices] = useState<IDevice[]>([])
  const [sizes, setSizes] = useState<ISize[]>([])
  const [prices, setPrices] = useState<IPrice[]>([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage, setRowsPerPage] = useState(TRANSACTIONS_PER_PAGE)
  const [transactionType, setTransactionType] = useState(
    TRANSACTION_TYPES[0].value,
  )
  const [search, setSearch] = useState('')
  const [currentUser, setCurrentUser] = useState<IUser>()
  const [currentTransaction, setCurrentTransaction] = useState<ITransaction>()
  const [currentDevice, setCurrentDevice] = useState<IDevice>()
  const [orgId, setOrgId] = useState<string>('')
  const [selectedRows, setSelectedRows] = useState<ITransaction[]>([])
  const [selectedLocation, setSelectedLocation] = useState<string>(() => {
    const saved = localStorage.getItem('transactionsLocationFilter')
    return saved || ''
  })
  const [selectedStatus, setSelectedStatus] = useState<string>(() => {
    const saved = localStorage.getItem('transactionsStatusFilter')
    return saved || ''
  })

  const { getMany: getTransactions, getByIdPublic, endMany } = transactionsApi()
  const { getMany: getDevices, setMaintenanceMode } = deviceApi()
  const { getMany: getSizes } = sizeApi()
  const { getMany: getPrices } = priceApi()
  const { getOrgDetailsPublic } = organizationApi()

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { filteredColumns, setFilteredColumns } = useColumnFiltering({
    displayMessage,
  })

  const [anchorEl, setAnchorEl] = useState(null)
  const displayFilters = Boolean(anchorEl)

  const { loading, setLoading } = useLoadingState(true)
  const { modes } = useDeviceModes()
  const { loadingLocations, locationsOptions } = useLocations()

  const sizeOptions = useMemo(
    () =>
      sizes.map((size) => ({
        value: size.id,
        label: `${size.name} (${size.width}" x ${size.depth}" x ${size.height}")`,
      })),
    [sizes],
  )

  const priceOptions = useMemo(
    () =>
      prices.map((price) => ({
        value: price.id,
        label: `${price.name} (£ ${price.amount} / ${price.unit})`,
      })),
    [prices],
  )

  const { order, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrder: 'default',
      defaultOrderBy: 'name',
      entity: 'transactions',
      nestedProps: [
        {
          columnValue: 'device_name',
          path: 'device.name',
          defaultValue: 0,
        },

        {
          columnValue: 'locker_number',
          path: 'device.locker_number',
          defaultValue: 0,
        },
        {
          columnValue: 'location',
          path: 'device.location.name',
          defaultValue: 0,
        },
        {
          columnValue: 'type',
          path: 'event_type',
          defaultValue: '',
        },
        {
          columnValue: 'status',
          path: 'event_status',
          defaultValue: 'in_progress',
        },
        {
          columnValue: 'start_date',
          path: 'started_at',
          defaultValue: '',
        },
        {
          columnValue: 'end_date',
          path: 'ended_at',
          defaultValue: '',
        },
        {
          columnValue: 'refund',
          path: 'total',
          defaultValue: 0,
        },
      ],
    })

  const handleIncomingTransaction = async (incomingTx: ITransaction) => {
    const publicTx = await getByIdPublic(incomingTx.id)
    if (!publicTx) return

    const mergedTx: ITransaction = {
      ...incomingTx,
      device: {
        ...(incomingTx.device || {}),
        location: {
          ...(incomingTx.device?.location || {}),
          name: publicTx.location_name,
        },
        name: publicTx.device_name,
      },
      user: {
        ...(incomingTx.user || {}),
        phone_number: publicTx.user_phone,
      },
    }

    setTransactions((prev) => {
      const idx = prev.items.findIndex((t) => t.id === mergedTx.id)
      if (idx === -1) {
        return { ...prev, items: [mergedTx, ...prev.items] }
      }

      const updatedItems = [...prev.items]
      updatedItems[idx] = mergedTx
      return { ...prev, items: updatedItems }
    })
  }

  const loadTableFilters = (firstItem: any) => {
    try {
      const storageKey = 'filteredColumns_transactions'
      const storedColumns = getStoredTableFilters(storageKey)

      if (storedColumns) {
        setFilteredColumns(storedColumns)
      } else {
        const generatedColumns = generateFilters(
          firstItem,
          EVENT_DEFAULTS,
          EVENT_SHORT_DEFAULTS,
        )
        setFilteredColumns(generatedColumns)
        const storedFilters = JSON.parse(
          localStorage.getItem('tableFilters') || '{}',
        )
        storedFilters[storageKey] = generatedColumns
        localStorage.setItem('tableFilters', JSON.stringify(storedFilters))
      }
    } catch (error) {
      displayMessage(
        'Error loading table filters: ' + (error as Error).message,
        'error',
      )
    }
  }

  const fetchTransactions = async () => {
    try {
      setLoading(true)
      const data = await getTransactions(1, 10000, transactionType, search)
      setTransactions(data)
      setTransactionsBackup([...data.items])

      if (data.items.length > 0) {
        loadTableFilters(data.items[0])
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    transaction: ITransaction,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, transaction]
        : previousValue.filter((row) => row.id !== transaction.id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() => (checked ? transactions.items.map((t) => t) : []))
  }

  const handleCancelMany = async () => {
    const cancellableRows = selectedRows.filter(
      (row) =>
        row.event_status !== 'Finished'.toLocaleLowerCase() &&
        row.event_status !== 'Canceled'.toLocaleLowerCase(),
    )

    if (cancellableRows.length === 0) {
      displayMessage(
        'One or more selected transactions are already finished or canceled',
        'info',
      )
      return
    }

    try {
      await endMany(cancellableRows.map((t) => t.id))
      displayMessage(
        `${cancellableRows.length} transaction${
          cancellableRows.length > 1 ? 's' : ''
        } ended successfully`,
        'success',
      )
      setSelectedRows([])
      fetchTransactions()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleSetMaintenanceMany = async () => {
    try {
      await setMaintenanceMode(
        selectedRows.map((t) => t.device.id),
        true,
      )
      displayMessage(
        `${selectedRows.length} device${
          selectedRows.length > 1 ? 's' : ''
        } set to maintenance`,
        'success',
      )
      setSelectedRows([])
      fetchTransactions()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleMultiSelectAction = async () => {
    if (action === 'end') {
      await handleCancelMany()
    } else {
      await handleSetMaintenanceMany()
    }
  }

  const fetchDevices = async (): Promise<void> => {
    try {
      const data = await getDevices(1, 10000)
      setDevices(data.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchSizes = async (): Promise<void> => {
    try {
      const data = await getSizes(1, 10000)
      setSizes(data.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const fetchPrices = async (): Promise<void> => {
    try {
      const data = await getPrices(1, 10000)
      setPrices(data.items)
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const fetchOrgId = async () => {
    const orgName = getOrganizationFromHost()
    const orgData = await getOrgDetailsPublic(orgName)
    setOrgId(orgData.id)
  }

  const handleSelectLocation = (
    event: React.SyntheticEvent,
    value: { value: string; label: string } | null,
  ) => {
    const newValue = value ? value.value : ''
    setSelectedLocation(newValue)
    localStorage.setItem('transactionsLocationFilter', newValue)
  }

  const handleSelectStatus = (
    event: React.SyntheticEvent,
    value: { value: string; label: string } | null,
  ) => {
    const newValue = value ? value.value : ''
    setSelectedStatus(newValue)
    localStorage.setItem('transactionsStatusFilter', newValue)
  }

  const visibleRows = useMemo(() => {
    const rows = getPaginatedSlice(currentPage, rowsPerPage, transactions.items)
    return getVisibleRowsSorted(rows, transactions.items)
  }, [transactions, search, order, orderBy, rowsPerPage, currentPage])

  useEffect(() => {
    let filtered = transactionsBackup

    if (selectedLocation && selectedLocation !== 'all') {
      filtered = filtered.filter(
        (t) => t.device.id_location === selectedLocation,
      )
    }

    if (selectedStatus && selectedStatus !== 'all') {
      filtered = filtered.filter((t) => t.event_status === selectedStatus)
    }

    setTransactions((prev) => ({
      ...prev,
      total: filtered.length,
      items: filtered,
    }))
  }, [selectedLocation, selectedStatus, transactionsBackup])

  useEffect(() => {
    fetchTransactions()
    fetchDevices()
    fetchSizes()
    fetchPrices()
    fetchOrgId()
  }, [])

  useEffect(() => {
    fetchTransactions()
  }, [search, currentPage, rowsPerPage, transactionType])

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button
          disabled={loading}
          variant="contained"
          name="filterByType"
          onClick={(event: any) => {
            setAnchorEl(event.currentTarget)
          }}
        >
          Filter by type
        </Button>
      ),
    },
    {
      display: true,
      isMenu: true,
      render: (
        <Menu
          id="types-menu"
          anchorEl={anchorEl}
          keepMounted
          open={displayFilters}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            elevation: 0,
            sx: {
              maxHeight: '500px',
              overflow: 'auto',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
              '& .MuiList-padding': {
                pt: '10px',
                pb: '10px',
                pl: '10px',
                pr: '10px',
              },
            },
          }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <Typography padding="10px">Filter transactions by mode</Typography>
          {modes.map((mode, index) => (
            <div key={mode.value}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '250px',
                  padding: '10px',
                }}
              >
                <Typography>{mode.label}</Typography>
                <Switch
                  checked={transactionType === mode.value}
                  defaultChecked={mode.value === 'all'}
                  onChange={() => {
                    if (transactionType === mode.value) {
                      setTransactionType('all')
                    } else {
                      setTransactionType(mode.value)
                    }
                  }}
                />
              </Box>
              {index !== modes.length - 1 && <Divider light />}
            </div>
          ))}
        </Menu>
      ),
    },
    {
      display: true,
      render: (
        <Autocomplete
          value={
            locationsOptions.find(
              (location) => location.value === selectedLocation,
            ) || null
          }
          disablePortal
          options={locationsOptions}
          renderInput={(params) => (
            <TextField {...params} label="Select a location..." />
          )}
          onChange={handleSelectLocation}
          size="small"
          disabled={loadingLocations || loading}
          loadingText="Loading..."
        />
      ),
    },

    {
      display: true,
      render: (
        <Autocomplete
          value={
            EVENT_STATUS_OPTIONS.find(
              (status) => status.value === selectedStatus,
            ) || null
          }
          disablePortal
          options={EVENT_STATUS_OPTIONS}
          renderInput={(params) => (
            <TextField {...params} label="Select a status..." />
          )}
          onChange={handleSelectStatus}
          size="small"
          disabled={loading}
          loadingText="Loading..."
        />
      ),
    },
  ]
  const isDataLoadedAndEmpty = !loading && transactions.items.length === 0

  return (
    <>
      <Toolbar controls={controls.filter((control) => control.display)}>
        <ToolbarControls
          controls={controls.filter((control) => control.display)}
        />
        <ToolbarSearchbar
          handleSearch={handleSearch}
          filteredColumns={filteredColumns}
          setFilteredColumns={setFilteredColumns}
          onlySortable={false}
          storageKey="filteredColumns_transactions"
          local={true}
        />
      </Toolbar>
      {selectedRows.length > 0 && (
        <MultiSelectToolbar
          itemsSelected={selectedRows.length}
          model="event"
          itemsSelectedData={selectedRows}
          handleAction={handleMultiSelectAction}
          setAction={setAction}
          actionsAllowed={['end', 'putUnderMaintenance', 'export']}
        />
      )}

      {!isDataLoadedAndEmpty && (
        <Table>
          <SortableTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={filteredColumns.filter((c) => c.active)}
            handleSelectAll={handleSelectAll}
          />
          <TableBody>
            {!loading &&
              visibleRows.map((transaction) => (
                <TransactionRow
                  key={transaction.id}
                  transaction={transaction}
                  setCurrentUser={(user: IUser) => setCurrentUser(user)}
                  setCurrentTransaction={(transaction: ITransaction) =>
                    setCurrentTransaction(transaction)
                  }
                  devices={devices}
                  setCurrentDevice={(device: IDevice) =>
                    setCurrentDevice(device)
                  }
                  filteredColumns={filteredColumns}
                  displayMessage={displayMessage}
                  fetchTransactions={fetchTransactions}
                  handleSelectRow={handleSelectRow}
                  selected={selectedRows
                    .map((t) => t.id)
                    .includes(transaction.id)}
                />
              ))}

            {loading &&
              filteredColumns.map((_, index) => (
                <TableRow key={index}>
                  {filteredColumns.map((_, index) => (
                    <TableCell key={index}>
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          borderRadius: '10px',
                          my: '10px',
                        }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}

      <NoData condition={isDataLoadedAndEmpty} />
      <TablePagination
        totalItems={transactions.total}
        currentPage={currentPage}
        itemsPerPage={rowsPerPage}
        setCurrentPage={setCurrentPage}
        setItemsPerPage={setRowsPerPage}
      />

      {currentUser && (
        <SideBar open={!!currentUser} onClose={() => setCurrentUser(undefined)}>
          <UserForm
            onClose={() => setCurrentUser(undefined)}
            user={currentUser}
            allowEdit={false}
          />
        </SideBar>
      )}

      {currentTransaction && (
        <SideBar
          open={!!currentTransaction}
          onClose={() => setCurrentTransaction(undefined)}
        >
          <TransactionForm
            transaction={currentTransaction}
            displayMessage={displayMessage}
            success={fetchTransactions}
          />
        </SideBar>
      )}

      {currentDevice && (
        <SideBar
          open={!!currentDevice}
          onClose={() => setCurrentDevice(undefined)}
        >
          <DeviceForm
            device={currentDevice}
            onClose={() => {
              setCurrentDevice(undefined)
            }}
            sizeOptions={sizeOptions}
            priceOptions={priceOptions}
            locationOptions={locationsOptions}
            allowEdit={false}
          />
        </SideBar>
      )}
      <NotificationDialog
        open={dialog.isOpen}
        onClose={closeDialog}
        message={dialog.message}
        type={dialog.type}
      />
    </>
  )
}

export default Transactions
