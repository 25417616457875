import { TextField as MUITextField, FormControl, Tooltip } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { ITextFieldProps, ErrorMessage } from 'components'
import { validateFloatingField, validateIntegerField } from 'utils/helpers'
import { Help } from '@mui/icons-material'

const TextField = ({
  name,
  placeholder,
  label,
  disabled,
  rules,
  multiline,
  type,
  required = false,
  defaultValue = null,
  value,
  onChange: onChangeProp,
  onBlur,
  error,
  isReadOnly = false,
  ref = null,
  helperText = '',
  onlyInteger = false, // only int
  isNumeric = false, // int and float
  id,
  tooltip,
}: ITextFieldProps & { readOnly?: boolean; id?: string }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value } }) => (
          <MUITextField
            fullWidth
            disabled={disabled}
            label={
              <>
                {label}
                {tooltip && (
                  <Tooltip
                    sx={{
                      transform: 'translate(1px, 5px)',
                    }}
                    placement="top-start"
                    arrow
                    title={tooltip}
                  >
                    <Help />
                  </Tooltip>
                )}
              </>
            }
            placeholder={placeholder}
            multiline={multiline}
            type={type}
            {...(onChange && {
              onChange: (...args) => {
                onChange(...args)
                if (onChangeProp) onChangeProp(...args)
              },
            })}
            {...(onBlur && { onBlur: onBlur })}
            error={error}
            defaultValue={defaultValue}
            value={
              value === undefined
                ? undefined
                : onlyInteger
                ? validateIntegerField(value)
                : isNumeric
                ? validateFloatingField(value)
                : String(value).replace(',', '')
            }
            required={required}
            InputProps={{
              readOnly: isReadOnly,
            }}
            InputLabelProps={{ shrink: true }}
            helperText={helperText}
            id={id}
          />
        )}
      />

      <ErrorMessage errors={errors} name={name} />
    </FormControl>
  )
}
export default TextField
