import {
  ChangeEvent,
  useState,
  useEffect,
  SyntheticEvent,
  useMemo,
} from 'react'
import { Box } from '@mui/material'
import {
  Table,
  TableBody,
  TablePagination,
  Button,
  SideBar,
  NotificationDialog,
} from 'components'
import UserForm from './UserForm'
import UserRow from './UserRow'
import { userApi } from '../../../../resources/user'
import { IUserList, IUser } from 'models'
import { DEFAULT_PAGE, ITEMS_PER_PAGE } from 'constants/pagination'
import useDialog from 'hooks/useDialog'
import MultiSelectToolbar from 'components/Table/components/MultiSelectToolbar/MultiSelectToolbar'
import useSortableHeader from 'hooks/useSortableHeader'
import SortableTableHead from 'components/Table/components/TableHead/SortableTableHead'
import useColumnFiltering from 'hooks/useColumnFiltering'
import NoData from 'components/PageBase/NoData'
import LoadingTableData from 'components/Table/components/LoadingTableData/LoadingTableData'
import Toolbar from 'components/Toolbar/Toolbar'
import ToolbarControls from 'components/Toolbar/components/ToolbarControls'
import ToolbarSearchbar from 'components/Toolbar/components/ToolbarSearchbar'
import { ToolbarControl } from 'types'
import useLoadingState from 'hooks/useLoadingState'
import { generateFilters } from 'utils/helpers'
import { USER_DEFAULTS, USER_SHORT_DEFAULTS } from 'pages/People/constants'
const Users = (): React.ReactElement => {
  const [users, setUsers] = useState<IUserList>({
    items: [],
    total: 0,
    pages: 0,
  })
  const [usersBackup, setUsersBackup] = useState<IUser[]>([])
  const [search, setSearch] = useState('')
  const [rowsPerPage, setRowsPerPage] = useState(ITEMS_PER_PAGE)
  const [open, setOpen] = useState(false)
  const [currentUser, setCurrentUser] = useState<IUser>()
  const [currentTab, setCurrentTab] = useState<string>('IndividualUpload')
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectedRowsData, setSelectedRowsData] = useState<any[]>([])

  const { getMany, deleteUser } = userApi()

  const { order, setOrder, orderBy, handleRequestSort, getVisibleRowsSorted } =
    useSortableHeader({
      defaultOrderBy: 'name',
      entity: 'users',
      nestedProps: [],
    })

  const { dialog, displayMessage, closeDialog } = useDialog()
  const { loading, setLoading } = useLoadingState(true)

  const { filteredColumns, setFilteredColumns, getDefaultFilters } =
    useColumnFiltering({
      displayMessage,
    })

  const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)
  }
  const handleSelectRow = (
    event: React.ChangeEvent,
    checked: boolean,
    id: string,
  ) => {
    setSelectedRows((previousValue) =>
      checked
        ? [...previousValue, id]
        : previousValue.filter((rowId) => rowId !== id),
    )

    setSelectedRowsData((previousValue) =>
      checked
        ? [...previousValue, users.items.find((user) => user.id === id)]
        : previousValue.filter((row) => row.id !== id),
    )
  }

  const handleSelectAll = (event: React.ChangeEvent, checked: boolean) => {
    setSelectedRows(() =>
      checked ? users.items.map((device) => device.id) : [],
    )

    setSelectedRowsData(() => (checked ? users.items : []))
  }

  const handleDeleteMany = async () => {
    try {
      await deleteUser(selectedRows)
      displayMessage(
        `${selectedRows.length} item${
          selectedRows.length > 1 ? 's' : ''
        } deleted successfully`,
        'success',
      )
      setSelectedRows([])
      fetchUsers()
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    }
  }
  const fetchUsers = async (
    showNewestFirst: boolean = false,
  ): Promise<void> => {
    try {
      setLoading(true)
      const users = await getMany(currentPage + 1, rowsPerPage, search)
      setUsersBackup([...users.items])
      setUsers(users)
      if (showNewestFirst) {
        setOrder('default')
      }
    } catch (error) {
      displayMessage(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleOpen = (): void => {
    setOpen(true)
  }
  const handleClose = (): void => {
    setOpen(false)
    setCurrentUser(undefined)
  }

  const handleEdit = (user: IUser): void => {
    setCurrentUser(user)
    setOpen(true)
  }

  const handleChange = (event: SyntheticEvent, newValue: string): void => {
    setCurrentTab(newValue)
  }

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    getDefaultFilters()
    fetchUsers()
  }, [search, currentPage, rowsPerPage])

  const visibleRows = useMemo(
    () => getVisibleRowsSorted(users.items, usersBackup),
    [users, order, orderBy],
  )

  const controls: ToolbarControl[] = [
    {
      display: true,
      render: (
        <Button disabled={loading} variant="contained" onClick={handleOpen}>
          Add User
        </Button>
      ),
    },
  ]

  return (
    <>
      <Box>
        <Toolbar controls={controls.filter((control) => control.display)}>
          <ToolbarControls
            controls={controls.filter((control) => control.display)}
          />
          <ToolbarSearchbar
            handleSearch={handleSearch}
            filteredColumns={filteredColumns}
            setFilteredColumns={setFilteredColumns}
            storageKey="filteredColumns_users"
          />
        </Toolbar>
        {selectedRows.length > 0 && (
          <MultiSelectToolbar
            itemsSelected={selectedRows.length}
            handleAction={handleDeleteMany}
            actionsAllowed={['delete', 'export']}
            itemsSelectedData={selectedRowsData}
            model="user"
          />
        )}
        {!loading && users.items.length > 0 && (
          <>
            <Table>
              <SortableTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headers={filteredColumns.filter((c) => c.active)}
                handleSelectAll={handleSelectAll}
              />
              <TableBody>
                {visibleRows.map((user) => (
                  <UserRow
                    key={user.id}
                    user={user}
                    onEdit={handleEdit}
                    displayMessage={displayMessage}
                    success={() => fetchUsers()}
                    filteredColumns={filteredColumns}
                    handleSelectRow={handleSelectRow}
                    selected={selectedRows.includes(user.id)}
                  />
                ))}
              </TableBody>
            </Table>
            <TablePagination
              totalItems={users?.total}
              currentPage={currentPage}
              itemsPerPage={rowsPerPage}
              setCurrentPage={setCurrentPage}
              setItemsPerPage={setRowsPerPage}
            />
          </>
        )}

        <LoadingTableData isLoading={loading} />

        <NoData condition={!loading && users.items.length === 0} />

        <SideBar open={open} onClose={handleClose}>
          <UserForm
            success={fetchUsers}
            onClose={handleClose}
            user={currentUser}
            displayMessage={displayMessage}
          />
        </SideBar>

        <NotificationDialog
          open={dialog.isOpen}
          onClose={closeDialog}
          message={dialog.message}
          type={dialog.type}
        />
      </Box>
    </>
  )
}

export default Users
