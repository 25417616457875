import { combineReducers } from 'redux'
import CustomizerReducer from './customizer/CustomizerReducer'
import lastLocationReducer from './locker-wall/lastLocationReducer'
import messageReducer from './message/messageReducer'
import currencyReducer from './currency/currencyReducer'
import lastModeFilterReducer from './notification/lastModeFilterReducer'
import userSessionReducer from './user-session/userSessionReducer'

const RootReducers = combineReducers({
  CustomizerReducer,
  lastLocationReducer,
  messageReducer,
  currencyReducer,
  lastModeFilterReducer,
  userSessionReducer,
})

export default RootReducers
