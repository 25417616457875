import { DeviceType } from 'models'

export const formatDevicePayload = (data): FormData => {
  const payload = new FormData()
  payload.append('name', data.name)
  payload.append('item', data.item || '')
  payload.append('item_description', data.item_description || '')
  payload.append('locker_number', data.locker_number)
  payload.append('mode', data.mode)
  payload.append('id_price', data.id_price || '')
  payload.append('id_size', data.id_size || '')
  payload.append('id_location', data.id_location)
  payload.append('id_product', data.id_product || '')
  payload.append('status', data.status)
  payload.append('hardware_type', data.hardware_type)
  if (data.image) {
    payload.append('image', data.image)
  }
  payload.append('price_required', data.price_required)
  payload.append('ada_compliance', data.ada_compliance)
  payload.append('require_image', data.require_image)
  switch (data.hardware_type) {
    case DeviceType.linka:
      payload.append('mac_address', data?.mac_address)
      break
    case DeviceType.spintly:
      payload.append('integration_id', data?.integration_id)
      break
    case DeviceType.ojmar:
      payload.append('user_code', data?.user_code)
      payload.append('master_code', data?.master_code)
      payload.append('locker_udn', data?.locker_udn)
      break
    case DeviceType.gantner:
      payload.append('gantner_id', data?.gantner_id)
      break
    case DeviceType.harbor:
      payload.append('harbor_tower_id', data?.harbor_tower_id)
      payload.append('harbor_locker_id', data?.harbor_locker_id)
      break
    case DeviceType.dclock:
      payload.append('dclock_terminal_no', data?.dclock_terminal_no)
      payload.append('dclock_box_no', data?.dclock_box_no)
      break
    case DeviceType.kerong:
      payload.append('circuit_unit', data?.circuit_unit)
      payload.append('hook_port', data?.hook_port)
      break
    case DeviceType.headleader:
      payload.append('headboard', data?.headboard)
      payload.append('port', data?.port)
      break
  }

  return payload
}
