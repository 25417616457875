import {
  Autocomplete,
  Box,
  Checkbox,
  TextField as MUITextField,
} from '@mui/material'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { Form, TextField, Image, IDialogType } from 'components'
import { IProduct } from 'models/Product'
import { productApi } from 'resources/product/api'
import { ChangeEvent, useEffect, useState } from 'react'
import { FormWrapper } from 'components/Form/components/FormWrapper'
import { FormActions } from 'components/Form/components/FormActions'
import { IProductGroup } from 'models/ProductGroup'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import useLoadingState from 'hooks/useLoadingState'
import ImageUploader from 'components/ImageUploader/ImageUploader'
import { DropdownOption } from 'types'
import LoadingFormData from 'components/PageBase/LoadingFormData'

interface IMultiEditProductFormProps {
  products: string[]
  onClose: () => void
  displayMessage?: (message: string | JSX.Element, type?: IDialogType) => void
  success: (showNewestFirst?: boolean) => void
  groups: IProductGroup[]
}

const MultiEditProductForm = ({
  products,
  onClose,
  displayMessage,
  success,
  groups,
}: IMultiEditProductFormProps) => {
  const [image, setImage] = useState<File | null>(null)
  const [previewImage, setImagePreview] = useState('')
  const [groupsOptions, setGroupsOptions] = useState<DropdownOption[]>([])
  const [selectedGroup, setSelectedGroup] = useState<DropdownOption | null>(
    null,
  )
  const { patch } = productApi()

  const { loading, setLoading } = useLoadingState()
  const { loading: groupsLoading, setLoading: setGroupsLoading } =
    useLoadingState()

  const methods = useForm<IProduct>({})

  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const file: File | undefined = event?.target?.files?.[0]
    const isImage = file && /^image\//.test(file.type)

    if (!isImage) {
      displayMessage?.('Only images are allowed!', 'info')
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = (e) =>
      e.target && e.target.result && setImagePreview(e.target.result as string)

    setImage(file ?? null)
  }

  const handleGroupSelection = (event: React.SyntheticEvent, value: any) => {
    setSelectedGroup(value)
  }

  const buildGroupsOptions = () => {
    if (groups.length > 0) {
      const autoCompleteOptions = groups.map((group) => ({
        label: group.name,
        value: group.id,
      }))
      const sortedOptions = autoCompleteOptions.sort((a, b) => {
        if (a.label < b.label) {
          return -1
        }
        if (a.label > b.label) {
          return 1
        }
        return 0
      })
      setGroupsOptions(sortedOptions)
    }

    setGroupsLoading(false)
  }
  const onSubmit: SubmitHandler<IProduct> = async (formData: IProduct) => {
    try {
      setLoading(true)

      const productData = {
        ...(formData.name && { name: formData.name }),
        ...(formData.price && { price: formData.price }),
        ...(formData.description && { description: formData.description }),
        ...(formData.sales_price && { sales_price: formData.sales_price }),
        ...(formData.msrp && { msrp: formData.msrp }),
        ...(formData.serial_number && {
          serial_number: formData.serial_number,
        }),
        ...(selectedGroup && { id_product_group: selectedGroup.value }),
      }

      await patch(products, productData)
      onClose()
      displayMessage?.('Products updated successfully!', 'success')
      success()
    } catch (error) {
      displayMessage?.(`${(error as Error).message}`, 'error')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    setGroupsLoading(true)
    buildGroupsOptions()
  }, [groups])

  const disableSubmit = Object.keys(methods.formState.errors).length > 0

  const MultiEditProductForm = (
    <>
      <FormProvider {...methods}>
        <Form onSubmit={onSubmit}>
          <FormWrapper title={'Edit Products'}>
            {previewImage && (
              <Image
                src={previewImage}
                alt="preview"
                style={{ outline: 'none' }}
              />
            )}

            <ImageUploader
              buttonText={'Update Image'}
              helpText="Upload an image (400x250) in high resolution; it may be cropped to a square."
              onChange={handleImageUpload}
            />

            <TextField
              name="name"
              label="Product Name *"
              placeholder="Product Name"
              rules={{
                required: 'Product name is required',
              }}
            />

            <TextField
              name="description"
              label="Description"
              placeholder="Description"
            />

            <Autocomplete
              value={selectedGroup}
              options={groupsOptions}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              )}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <MUITextField {...params} label="Select a group..." />
              )}
              onChange={handleGroupSelection}
              loading={groupsLoading}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '10px',
              }}
            >
              <TextField
                name="price"
                label="Cost"
                placeholder="$$.$$"
                rules={
                  methods.watch('price')
                    ? {
                        validate: (value) =>
                          value > 0 || 'Cost must be greater than 0',
                      }
                    : {}
                }
                isNumeric={true}
              />
              <TextField
                name="sales_price"
                label="Sale Price"
                placeholder="$$.$$"
                rules={
                  methods.watch('sales_price')
                    ? {
                        validate: (value) =>
                          value > 0 || 'Sale price must be greater than 0',
                      }
                    : {}
                }
                isNumeric={true}
              />
              <TextField
                name="msrp"
                label="MSRP"
                placeholder="MSRP"
                rules={
                  methods.watch('msrp')
                    ? {
                        validate: (value) =>
                          value > 0 || 'MSRP must be greater than 0',
                      }
                    : {}
                }
                isNumeric={true}
              />
            </Box>

            <FormActions
              onClose={onClose}
              loading={loading}
              disableSubmit={disableSubmit}
            />
          </FormWrapper>
        </Form>
      </FormProvider>
    </>
  )

  if (groupsLoading) {
    return <LoadingFormData />
  }

  return MultiEditProductForm
}
export default MultiEditProductForm
